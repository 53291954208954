import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';
import { Loader } from '../common/Loader';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Box } from '@mui/material';

const ALLOWED_FILE_TYPES = ['mp3', 'wav', 'ogg', 'm4a', 'wma', 'aac', 'flac', 'mp4', 'avi', 'mov', 'mkv', 'webm'];
const MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB in bytes

const FileUploader = ({ onUploadComplete }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const db = getFirestore();
  const auth = getAuth();

  // Trigger entrance animation after mount
  useEffect(() => {
    // Small delay to ensure smooth animation
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  const containerStyle = {
    width: '100%',
    height: '210px',
    padding: '50px 0px 0px 0px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: isDragging ? `2px dashed ${theme.palette.primary.main}` : 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
    transition: 'opacity 0.6s ease, transform 0.6s ease',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '200%',
      height: '100%',
      transition: 'transform 0.6s ease',
      transform: isDragging ? 'translateX(75%)' : 'translateX(0)',
    }
  };

  const circleStyle = {
    width: '75px',
    height: '75px',
    borderRadius: '50%',
    backgroundColor: '#f9c0f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    transform: isVisible 
      ? (isHovered ? 'scale(1.1) translateY(0)' : 'scale(1) translateY(0)') 
      : 'scale(0.9) translateY(20px)',
    opacity: isVisible ? 1 : 0,
    boxShadow: isHovered ? '0 4px 20px rgba(249, 192, 245, 0.4)' : 'none',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      right: '-2px',
      bottom: '-2px',
      borderRadius: '50%',
      border: '2px solid transparent',
      background: 'linear-gradient(45deg, #f9c0f5, #fcd4f8)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      opacity: isHovered ? 1 : 0,
      transition: 'opacity 0.3s ease'
    }
  };

  const plusStyle = {
    color: 'black',
    fontSize: '100px',
    fontWeight: '100',
    fontFamily: 'Gill Sans',
    lineHeight: '10px',
    transition: 'transform 0.3s ease',
    transform: isHovered ? 'rotate(90deg)' : 'rotate(0)',
  };

  const textStyle = {
    color: '#262730',
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '16px',
    transition: 'all 0.5s ease',
    opacity: isVisible ? (isHovered ? 0.8 : 1) : 0,
    transform: isVisible 
      ? (isHovered ? 'translateY(2px)' : 'translateY(0)') 
      : 'translateY(20px)',
    transitionDelay: '0.2s',
  };

  const errorStyle = {
    color: 'red',
    textAlign: 'center',
    animation: 'slideIn 0.3s ease'
  };

  const validateAndUploadFile = async (file) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !ALLOWED_FILE_TYPES.includes(fileExtension)) {
      setError('Invalid file type. Please select an allowed file type.');
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      setError('File size exceeds 1GB limit.');
      return;
    }
  
    setIsProcessing(true);
    setError(null);
  
    try {
      // Verify auth state
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      console.log('Current user:', auth.currentUser.uid);

      console.log('Starting upload process for:', file.name);
  
      // Get signed URL using Cloud Function
      const getSignedUrl = httpsCallable(functions, 'generate_signed_url');
      console.log('Requesting signed URL...');
      
      const { data } = await getSignedUrl({ fileName: file.name });
      console.log('Received signed URL response:', data);
  
      if (!data.signedUrl) {
        throw new Error('No signed URL received');
      }
  
      // Upload file using signed URL
      console.log('Uploading file to storage...');
      const uploadResponse = await fetch(data.signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      });
  
      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }
  
      console.log('File upload completed successfully');
  
      // Generate transcript ID
      const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
      const transcriptId = `${timestamp}_${file.name.replace(/[^a-zA-Z0-9]/g, '')}`;
      console.log('Generated transcript ID:', transcriptId);

      // Create initial Firestore document
      try {
        console.log('Creating Firestore document...');
        const transcriptRef = doc(db, 'user_transcripts', transcriptId);
        const docData = {
          transcript_id: transcriptId,
          filename: file.name,
          status: 'uploaded',
          user_id: auth.currentUser.uid,
          createdAt: new Date(),
          transcript_name: file.name,
          tempBlobName: data.fileId, // Temporary storage location
        };
        console.log('Document data:', docData);
        
        await setDoc(transcriptRef, docData);
        console.log('Firestore document created successfully');
      } catch (firestoreError) {
        console.error('Firestore error:', firestoreError);
        throw new Error(`Failed to create transcript document: ${firestoreError.message}`);
      }
  
      // Call onUploadComplete with upload info
      onUploadComplete({
        fileId: data.fileId,
        filename: file.name,
        contentType: file.type,
        transcriptId: transcriptId
      });
  
    } catch (error) {
      console.error('Upload error details:', error);
      setError(error.message || 'Error uploading file');
    } finally {
      setIsProcessing(false);
    }
  };


  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      validateAndUploadFile(files[0]);
    }
  };

  const onFileSelect = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      validateAndUploadFile(file);
    }
  };

  return (
    <Box 
      sx={containerStyle}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        {!isProcessing ? (
          <>
            <input
              type="file"
              onChange={onFileSelect}
              style={{ display: 'none' }}
              ref={fileInputRef}
              accept={ALLOWED_FILE_TYPES.map(type => `.${type}`).join(',')}
            />
            <Box
              sx={{
                ...circleStyle,
                transitionDelay: '0.1s',
              }}
              onClick={() => fileInputRef.current?.click()}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span style={plusStyle}>+</span>
            </Box>
            <p style={textStyle}>
              <strong>Upload audio or video file</strong>
            </p>
          </>
        ) : (
          <Box sx={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'scale(1)' : 'scale(0.9)',
            transition: 'opacity 0.5s ease, transform 0.5s ease',
            animation: 'pulse 2s infinite',
            '@keyframes pulse': {
              '0%': {
                transform: 'scale(0.95)',
                opacity: 0.8,
              },
              '50%': {
                transform: 'scale(1.05)',
                opacity: 1,
              },
              '100%': {
                transform: 'scale(0.95)',
                opacity: 0.8,
              },
            }
          }}>
            <Loader size="medium" text="Transferring your file to our secure storage"/>
          </Box>
        )}
        {error && (
          <Box sx={{
            ...errorStyle,
            '@keyframes slideIn': {
              from: {
                transform: 'translateY(-10px)',
                opacity: 0,
              },
              to: {
                transform: 'translateY(0)',
                opacity: 1,
              },
            }
          }}>
            {error}
          </Box>
        )}
      </div>
    </Box>
  );
};

export default FileUploader;