// src/components/transcript_editor/AudioPlayer.tsx
import React, { useEffect, useCallback, useRef } from 'react';
import { Box, IconButton, Stack, Typography, Tooltip, Menu, MenuItem } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AudioPlayerProps } from './types';
import CustomProgressBar from './CustomProgressBar';
import { formatTime } from './utils';

const AudioPlayer: React.FC<AudioPlayerProps> = ({ 
  audioUrl,
  audioRef,
  onTimeUpdate,
  showSpeakers,
  showTimestamps,
  onToggleSpeakers,
  onToggleTimestamps,
  concatLevel,
  onConcatLevelChange,
  segments,
  activeSegmentIndex,
  speakerColors,
  onUndo,
  onRedo,
  historyState,
  hasUnsavedChanges
}) => {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const [duration, setDuration] = React.useState<number>(0);
  const [mobileMenuAnchor, setMobileMenuAnchor] = React.useState<null | HTMLElement>(null);
  const isSeekingRef = useRef<boolean>(false);
  const requestAnimationFrameRef = useRef<number | null>(null);

  // Audio event handlers
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleLoadedMetadata = () => setDuration(audio.duration);
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      onTimeUpdate(0);
    };
    
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('ended', handleEnded);
    
    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [audioRef, onTimeUpdate]);

  // Time update handling
  useEffect(() => {
    const updateTime = () => {
      const audio = audioRef.current;
      if (!audio || isSeekingRef.current) return;

      setCurrentTime(audio.currentTime);
      onTimeUpdate(audio.currentTime);
      requestAnimationFrameRef.current = requestAnimationFrame(updateTime);
    };

    if (isPlaying) {
      requestAnimationFrameRef.current = requestAnimationFrame(updateTime);
    }

    return () => {
      if (requestAnimationFrameRef.current !== null) {
        cancelAnimationFrame(requestAnimationFrameRef.current);
      }
    };
  }, [isPlaying, audioRef, onTimeUpdate]);

  const handleSeek = useCallback((newTime: number) => {
    const audio = audioRef.current;
    if (!audio) return;
    
    isSeekingRef.current = true;
    setCurrentTime(newTime);
    audio.currentTime = newTime;
    onTimeUpdate(newTime);
    
    setTimeout(() => {
      isSeekingRef.current = false;
    }, 50);
  }, [audioRef, onTimeUpdate]);

  const togglePlay = useCallback(() => {
    const audio = audioRef.current;
    if (!audio) return;
    
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch(console.error);
    }
  }, [audioRef, isPlaying]);

  const handleConcatLevel = useCallback((increment: boolean) => {
    const newLevel = increment ? Math.min(concatLevel + 1, 3) : Math.max(concatLevel - 1, 0);
    onConcatLevelChange(newLevel);
  }, [concatLevel, onConcatLevelChange]);

  return (
    <div className="audio-player">
      <div className="player-container">
        <audio ref={audioRef} src={audioUrl} style={{ display: 'none' }} />

        <div className="player-controls">
          <div className="player-progress">
            <CustomProgressBar
              audioRef={audioRef}
              duration={duration}
              currentTime={currentTime}
              segments={segments}
              onSeek={handleSeek}
              activeSegmentIndex={activeSegmentIndex}
              speakerColors={speakerColors}
              showSpeakers={showSpeakers}
            />
          </div>

          <div className="player-main">
            <div className="controls-left">
              <IconButton 
                className="play-pause-btn"
                onClick={togglePlay}
                sx={{ 
                  bgcolor: isPlaying ? 'primary.main' : 'transparent',
                  '&:hover': { bgcolor: isPlaying ? 'primary.dark' : 'action.hover' }
                }}
              >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>

              <span className="time-display">
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            </div>

            <div className="controls-right">
              {/* Desktop Controls */}
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} className="controls-tools">
                <Tooltip title={showSpeakers ? "Hide speakers" : "Show speakers"}>
                  <IconButton 
                    className="speaker-toggle-btn"
                    onClick={onToggleSpeakers}
                    sx={{
                      bgcolor: showSpeakers ? 'white' : 'grey.300',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      '&:hover': { bgcolor: showSpeakers ? 'grey.100' : 'grey.400' }
                    }}
                  >
                    <PersonIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={showTimestamps ? "Hide timestamps" : "Show timestamps"}>
                  <IconButton 
                    className="timestamp-toggle-btn"
                    onClick={onToggleTimestamps}
                    sx={{
                      bgcolor: showTimestamps ? 'white' : 'grey.300',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      '&:hover': { bgcolor: showTimestamps ? 'grey.100' : 'grey.400' }
                    }}
                  >
                    <AccessTimeIcon />
                  </IconButton>
                </Tooltip>

                <div className="history-controls">
                  <Tooltip title="Undo">
                    <span>
                      <IconButton 
                        disabled={!historyState.canUndo} 
                        onClick={onUndo}
                        className="toolbar-button"
                      >
                        <UndoIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <Tooltip title="Redo">
                    <span>
                      <IconButton 
                        disabled={!historyState.canRedo} 
                        onClick={onRedo}
                        className="toolbar-button"
                      >
                        <RedoIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>

                <div className="segment-size-control">
                  <div className="size-buttons">
                    <IconButton 
                      className="size-buttons-minus"
                      onClick={() => handleConcatLevel(false)}
                      disabled={concatLevel === 0}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton
                      className="size-buttons-plus"
                      onClick={() => handleConcatLevel(true)}
                      disabled={concatLevel === 3}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
              </Box>

              {/* Mobile Menu */}
              <Box sx={{ display: { xs: 'block', md: 'none' } }} className="mobile-controls">
                <IconButton onClick={(e) => setMobileMenuAnchor(e.currentTarget)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={mobileMenuAnchor}
                  open={Boolean(mobileMenuAnchor)}
                  onClose={() => setMobileMenuAnchor(null)}
                >
                  <MenuItem onClick={() => {
                    onToggleSpeakers();
                    setMobileMenuAnchor(null);
                  }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <PersonIcon color={showSpeakers ? "primary" : "inherit"} />
                      <Typography>{showSpeakers ? "Hide Speakers" : "Show Speakers"}</Typography>
                    </Stack>
                  </MenuItem>
                  
                  <MenuItem onClick={() => {
                    onToggleTimestamps();
                    setMobileMenuAnchor(null);
                  }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <AccessTimeIcon color={showTimestamps ? "primary" : "inherit"} />
                      <Typography>{showTimestamps ? "Hide Timestamps" : "Show Timestamps"}</Typography>
                    </Stack>
                  </MenuItem>
                  
                  <MenuItem disabled={!historyState.canUndo} onClick={() => {
                    onUndo();
                    setMobileMenuAnchor(null);
                  }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <UndoIcon />
                      <Typography>Undo</Typography>
                    </Stack>
                  </MenuItem>
                  
                  <MenuItem disabled={!historyState.canRedo} onClick={() => {
                    onRedo();
                    setMobileMenuAnchor(null);
                  }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <RedoIcon />
                      <Typography>Redo</Typography>
                    </Stack>
                  </MenuItem>
                  
                  <MenuItem>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButton 
                        size="small"
                        onClick={() => handleConcatLevel(false)}
                        disabled={concatLevel === 0}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography>Segment Size</Typography>
                      <IconButton
                        size="small"
                        onClick={() => handleConcatLevel(true)}
                        disabled={concatLevel === 3}
                      >
                        <AddIcon />
                      </IconButton>
                    </Stack>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AudioPlayer);