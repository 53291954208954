  import React, { useContext, useState, useEffect } from 'react';
  import { AuthContext } from '@fireactjs/core';
  import { Link, useNavigate, useLocation } from 'react-router-dom';
  import { pathnames } from '@fireactjs/core';
  import { getFirestore, collection, query, where, orderBy, doc, getDoc, onSnapshot } from 'firebase/firestore';
  import { 
    List,
    Box, 
    Divider, 
    Avatar, 
    Menu, 
    MenuItem, 
    ListItemIcon, 
    ListItemText,
    Typography,
    ListItem,
    ListItemButton,
    CircularProgress,
    Tooltip,
  } from '@mui/material';
  import { CompactFileUploader } from '../upload/CompactFileUploader';
  import PersonIcon from '@mui/icons-material/Person';
  import LogoutIcon from '@mui/icons-material/Logout';
  import { getAuth, signOut } from 'firebase/auth';
  import TranscriptListItem from './TranscriptListItem';

  const InProgressTranscript = ({ transcript, isDrawerOpen, navigate }) => {
    // Check if transcript is more than 3 hours old
    const isExpired = () => {
      const createdAt = transcript.createdAt?.toDate();
      if (!createdAt) return true;
      
      const threeHoursAgo = new Date();
      threeHoursAgo.setHours(threeHoursAgo.getHours() - 3);
      
      return createdAt < threeHoursAgo;
    };

    const handleClick = () => {
      navigate(`/transcript/${transcript.transcript_id}/return`);
    };

    // Don't render if expired
    if (isExpired()) {
      return null;
    }

    if (!isDrawerOpen) {
      return (
        <Tooltip title={transcript.transcript_name} placement="right">
          <ListItem disablePadding>
            <ListItemButton 
              onClick={handleClick}
              sx={{
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  marginTop: '26px',
                  marginBottom: '26px',
                  backgroundColor: 'primary.main',
                  mx: 'auto'
                }}
              />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      );
    }

    return (
      <ListItem
        disablePadding
        sx={{ opacity: 0.8 }}
      >
        <ListItemButton 
          onClick={handleClick}
          sx={{
            backgroundColor: 'transparent',
            py: 1,
            display: 'flex',
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          <ListItemText 
            primary={transcript.transcript_name}
            secondary={
              <Typography variant="caption" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                {transcript.language?.toUpperCase() || '?'} • {Math.round(transcript.duration / 60)}:
                {String(Math.round(transcript.duration % 60)).padStart(2, '0')} •&nbsp;
                {transcript.createdAt?.toDate().toLocaleDateString('en-GB')}
              </Typography>
            }
            primaryTypographyProps={{
              noWrap: true
            }}
            sx={{ pr: 1 }}
          />
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            minWidth: 24,
            justifyContent: 'flex-end'
          }}>
            <CircularProgress size={16} />
          </Box>
        </ListItemButton>
      </ListItem>
    );
  };

  export const CustomMenu = ({ isDrawerOpen }) => {
    const { authUser } = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [transcripts, setTranscripts] = useState([]);
    const [inProgressTranscripts, setInProgressTranscripts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [credits, setCredits] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const auth = getAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore();

    const isTranscriptActive = (transcriptId) => {
      return location.pathname.includes(`/transcript/${transcriptId}`);
    };

    useEffect(() => {
      if (!authUser?.user?.uid) return;

      // Set up real-time listener for all transcripts
      const unsubscribe = onSnapshot(
        query(
          collection(db, 'user_transcripts'),
          where('user_id', '==', authUser.user.uid),
          orderBy('createdAt', 'desc')
        ),
        (snapshot) => {
          const allTranscripts = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          // Separate transcripts based on status
          const inProgress = allTranscripts.filter(t => 
            t.status === 'sent_to_transcription' || t.status === 'processing'
          );
          const completed = allTranscripts.filter(t => t.status === 'transcribed');

          setInProgressTranscripts(inProgress);
          setTranscripts(completed);
          setLoading(false);
        },
        (error) => {
          console.error('Error in real-time transcript updates:', error);
          setError('Failed to load transcripts');
          setLoading(false);
        }
      );

      return () => unsubscribe();
    }, [authUser?.user?.uid, db]);

    useEffect(() => {
      const fetchUserCredits = async () => {
        if (!authUser?.user?.uid) return;

        try {
          const creditsDoc = await getDoc(doc(db, 'user_credits', authUser.user.uid));
          if (creditsDoc.exists()) {
            setCredits(creditsDoc.data().credits);
          } else {
            setCredits(0);
          }
        } catch (err) {
          console.error('Error fetching user credits:', err);
          setCredits(0);
        }
      };

      fetchUserCredits();
    }, [authUser?.user?.uid, db]);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSignOut = () => {
      signOut(auth);
      handleClose();
    };

    const handleTranscriptClick = (transcript) => {
      const transcriptId = transcript.transcript_id;
      navigate(`/transcript/${transcriptId}`, { replace: true });
      if (location.pathname.includes('/transcript/')) {
        window.location.reload();
      }
    };

    const formatCredits = (creditMinutes) => {
      if (creditMinutes === null) return '-';
      const hours = creditMinutes / 60;
      return `${hours.toFixed(1)} credit-hours`;
    };

    const TranscriptList = () => {
      if (loading) {
        return (
          <ListItem>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', py: 2 }}>
              <CircularProgress size={24} />
            </Box>
          </ListItem>
        );
      }
    
      if (error) {
        return (
          <ListItem>
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          </ListItem>
        );
      }
    
      if (transcripts.length === 0 && inProgressTranscripts.length === 0) {
        return !isDrawerOpen && (
          <ListItem>
            <Typography variant="body2" color="text.secondary">
              No transcripts yet
            </Typography>
          </ListItem>
        );
      }
    
      return (
        <>
          {/* In Progress Transcripts */}
          {inProgressTranscripts.map((transcript) => (
            <InProgressTranscript
              key={transcript.id}
              transcript={transcript}
              isDrawerOpen={isDrawerOpen}
              navigate={navigate}
            />
          ))}

          {/* Completed Transcripts */}
          {transcripts.map((transcript) => (
            <TranscriptListItem
              key={transcript.id}
              transcript={transcript}
              isActive={isTranscriptActive(transcript.transcript_id)}
              isDrawerOpen={isDrawerOpen}
              onClick={() => handleTranscriptClick(transcript)}
              onUpdate={() => {}} // No need for manual update with real-time listener
            />
          ))}
        </>
      );
    };
    
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <List sx={{ paddingTop: '0px' }}>
          {!isDrawerOpen ? (
            <Tooltip title="New Transcript" placement="right">
              <div>
                <CompactFileUploader 
                  onUploadComplete={(fileData) => {
                    navigate(`/payment/${fileData.fileId}`, {
                      state: { fileData }
                    });
                  }}
                  isCollapsed={!isDrawerOpen} 
                />
              </div>
            </Tooltip>
          ) : (
            <CompactFileUploader 
              onUploadComplete={(fileData) => {
                navigate(`/payment/${fileData.fileId}`, {
                  state: { fileData }
                });
              }}
            />
          )}

          <Divider />
          
          <ListItem>
            <Typography variant="overline" color="text.secondary">
              Transcripts
            </Typography>
          </ListItem>

          <TranscriptList />
        </List>

        <Box sx={{ flexGrow: 1 }} />

        <Divider />
        <Box 
          onClick={handleClick}
          sx={{ 
            p: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          <Avatar 
            sx={{ 
              width: 40,
              height: 40,
              bgcolor: 'primary.main',
              color: 'primary.contrastText'
            }}
          >
            {authUser?.user?.email ? authUser.user.email[0].toUpperCase() : '?'}
          </Avatar>
          {isDrawerOpen && (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              overflow: 'hidden'
            }}>
              <Typography 
                variant="body2"
                sx={{ 
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              >
                {formatCredits(credits)}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {authUser?.user?.email}
              </Typography>
            </Box>
          )}
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={menuOpen}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem component={Link} to={pathnames.UserProfile}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  export default CustomMenu;