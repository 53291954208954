import * as React from 'react';
import { EditableSegmentProps } from './types';
import { speakerToPersonName, formatTime } from './utils';

const EditableSegment: React.FC<EditableSegmentProps> = ({
  segment,
  showSpeakers,
  showTimestamps,
  speakerColor,
  showSpeakerLabel,
  isPlaying,
  onPlay,
  onPause,
  onTextChange,
  onSpeakerNameChange,
  customSpeakerName,
  currentTime
}) => {
  const segmentRef = React.useRef<HTMLDivElement>(null);
  const textContentsRef = React.useRef<{ [key: string]: string }>({});
  const prevIsActiveRef = React.useRef<boolean>(false);
  const lastScrollRef = React.useRef<number>(0);
  const isEditingRef = React.useRef<boolean>(false);

  // Initialize text contents ref with initial segment texts
  React.useEffect(() => {
    segment.segments.forEach((seg) => {
      const key = `${seg.start}-${seg.end}`;
      textContentsRef.current[key] = seg.text;
    });
  }, [segment]);

  const isActive = currentTime >= segment.start && currentTime <= segment.end;
  
  const activeStyles = isActive ? {
    backgroundColor: 'rgba(249, 192, 245, 0.1)',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease'
  } : {};

  const isElementInViewport = React.useCallback(() => {
    if (!segmentRef.current) return false;
    const rect = segmentRef.current.getBoundingClientRect();
    const buffer = 150;
    return (
      rect.top >= -buffer &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + buffer
    );
  }, []);

  React.useEffect(() => {
    const shouldScroll = (isPlaying && isActive) || (!prevIsActiveRef.current && isActive);
    if (!shouldScroll || !segmentRef.current) return;

    const now = Date.now();
    if (now - lastScrollRef.current < 500) return;

    if (!isElementInViewport()) {
      lastScrollRef.current = now;
      segmentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    prevIsActiveRef.current = isActive;
  }, [isPlaying, isActive, isElementInViewport]);

  const handleTimestampClick = () => {
    if (isPlaying) {
      onPause();
    } else {
      onPlay();
    }
  };

  const handleTextKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.currentTarget.blur();
    }
  };

  const handleSpeakerKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.currentTarget.blur();
    }
  };

  const handleTextInput = (event: React.FormEvent<HTMLSpanElement>, segmentInfo: { start: number, end: number }) => {
    isEditingRef.current = true;
    const text = event.currentTarget.textContent || '';
    const key = `${segmentInfo.start}-${segmentInfo.end}`;
    textContentsRef.current[key] = text;
  };

  const handleTextBlur = (event: React.FocusEvent<HTMLSpanElement>, segmentInfo: { start: number, end: number }) => {
    isEditingRef.current = false;
    const key = `${segmentInfo.start}-${segmentInfo.end}`;
    const text = textContentsRef.current[key] || event.currentTarget.textContent || '';
    
    // Only update if text has changed
    if (text !== segment.segments.find(s => s.start === segmentInfo.start && s.end === segmentInfo.end)?.text) {
      onTextChange(text, segmentInfo.start, segmentInfo.end);
    }
  };

  const handleSpeakerNameEdit = (event: React.FocusEvent<HTMLDivElement>) => {
    const newName = event.currentTarget.textContent?.trim();
    if (newName && newName !== customSpeakerName && newName !== speakerToPersonName(segment.speaker)) {
      onSpeakerNameChange(segment.speaker, newName);
    }
  };

  return (
    <div 
      ref={segmentRef}
      className={`segment ${showSpeakers ? '' : 'hide-speaker'} ${showTimestamps ? '' : 'hide-timestamp'}`}
      style={activeStyles}
    >
      <div 
        className="speaker-color-bar"
        style={{ backgroundColor: speakerColor }}
      />

      {showSpeakers && (
        <div className="speaker-column">
          {showSpeakerLabel && (
            <div 
              className="speaker-label"
              style={{ backgroundColor: speakerColor }}
              contentEditable
              suppressContentEditableWarning
              onKeyDown={handleSpeakerKeyDown}
              onBlur={handleSpeakerNameEdit}
            >
              {customSpeakerName || speakerToPersonName(segment.speaker)}
            </div>
          )}
        </div>
      )}
      
      {showTimestamps && (
        <div className="timestamp-column">
          <button
            onClick={handleTimestampClick}
            className={`timestamp ${isPlaying ? 'active' : ''}`}
          >
            {formatTime(segment.start)}
          </button>
        </div>
      )}
      
      <div className="text-column">
        {segment.segments.map((seg, index) => (
          <React.Fragment key={`${seg.start}-${seg.end}`}>
            <span
              id={`segment-text-${seg.start}-${seg.end}`}
              className="segment-text"
              contentEditable
              suppressContentEditableWarning
              onInput={(e) => handleTextInput(e, seg)}
              onBlur={(e) => handleTextBlur(e, seg)}
              onKeyDown={handleTextKeyDown}
              dangerouslySetInnerHTML={{ __html: seg.text }}
            />
            {index < segment.segments.length - 1 && ' '}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default React.memo(EditableSegment);