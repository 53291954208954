import { InputSegment } from './types';
import { getStorage, ref, getBytes, uploadBytes } from 'firebase/storage';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const LOCAL_STORAGE_PREFIX = 'transcript_edit_';
const CACHE_VERSION = 1;

interface TranscriptState {
  segments: InputSegment[];
  speakerNames: Record<string, string>;
  settings: {
    showSpeakers: boolean;
    showTimestamps: boolean;
    concatLevel: number;
  };
  timestamp: number;
}

interface StateMetadata {
  version: number;
  paths: {
    original: string;
    edit: string;
  };
  lastSaved: number | null;
  settings?: {
    showSpeakers: boolean;
    showTimestamps: boolean;
    concatLevel: number;
  };
}

interface HistoryEntry {
  state: TranscriptState;
  description: string;
}

export class TranscriptHistoryManager {
  private undoStack: HistoryEntry[] = [];
  private redoStack: HistoryEntry[] = [];
  private currentState: TranscriptState | null = null;
  private originalState: TranscriptState | null = null;
  private metadata: StateMetadata | null = null;
  private transcriptId: string;
  private maxStackSize = 100;
  private db = getFirestore();
  private storage = getStorage();
  private initialized = false;
  private isReverted = false;
  private lastLoadPath: 'original' | 'edited' | 'cache' | null = null;

  constructor(transcriptId: string) {
    this.transcriptId = transcriptId;
  }

  async initialize(): Promise<TranscriptState> {
    const docRef = doc(this.db, 'user_transcripts', this.transcriptId);
    const docSnap = await getDoc(docRef);
  
    if (!docSnap.exists()) throw new Error('Transcript not found');
  
    const data = docSnap.data();
    const originalPath = data.transcript_path;
    const editPath = originalPath.replace('/transcripts/', '/transcripts_edits/');
    const isReverted = data.reverted || false;

    this.metadata = {
      version: CACHE_VERSION,
      paths: { original: originalPath, edit: editPath },
      lastSaved: null,
    };
  
    if (isReverted) {
      const originalState = await this.loadOriginalState();
      this.currentState = this.cloneState(originalState);
      this.initialized = true;
      this.lastLoadPath = 'original';
  
      await updateDoc(docRef, { reverted: false });
      return this.currentState;
    }
  
    return await this.normalInitializationSequence();
  }
  
  private async normalInitializationSequence(): Promise<TranscriptState> {
    if (!this.metadata) {
      throw new Error('Metadata not initialized');
    }

    // First load original state
    const originalState = await this.loadOriginalState();
      
    // Try to load from cache first
    const cached = await this.loadFromCache();
    if (cached) {
      this.currentState = cached;
      this.initialized = true;
      this.lastLoadPath = 'cache';
      return this.currentState;
    }

    // Quietly try to load edited version
    try {
      const editRef = ref(this.storage, this.metadata.paths.edit);
      const editBytes = await getBytes(editRef);
      const editData = JSON.parse(new TextDecoder().decode(editBytes));
      
      const editedState: TranscriptState = {
        segments: editData.segments,
        speakerNames: editData.speakerNames || {},
        settings: this.metadata.settings || {
          showSpeakers: true,
          showTimestamps: true,
          concatLevel: 1,
        },
        timestamp: Date.now(),
      };
      
      this.currentState = editedState;
      this.initialized = true;
      this.lastLoadPath = 'edited';
      return this.currentState;
    } catch (error: any) {
      // Silently use original state if no edited version exists
      if (error?.code === 'storage/object-not-found' || error?.message?.includes('404')) {
        this.currentState = this.cloneState(originalState);
        this.initialized = true;
        this.lastLoadPath = 'original';
        return this.currentState;
      }
      // Only throw if it's not a 404
      throw error;
    }
  }

  async saveToFirebase(): Promise<void> {
    if (!this.metadata) {
      throw new Error('Manager not properly initialized');
    }

    try {
      let stateToSave: TranscriptState;

      // If no edits made, use original state
      if (!this.currentState) {
        if (!this.originalState) {
          throw new Error('No state available to save');
        }
        stateToSave = this.cloneState(this.originalState);
      } else {
        stateToSave = this.cloneState(this.currentState);
      }

      const editRef = ref(this.storage, this.metadata.paths.edit);
      
      // Prepare the data to save to Storage
      // Include settings in the storage data as well
      const saveData = {
        segments: stateToSave.segments,
        speakerNames: stateToSave.speakerNames,
        settings: stateToSave.settings, // Include settings in storage
        version: CACHE_VERSION,
        timestamp: Date.now()
      };

      // Create the blob with proper content type
      const blob = new Blob([JSON.stringify(saveData)], { 
        type: 'application/json'
      });

      // Upload to Firebase Storage
      await uploadBytes(editRef, blob);

      // Update Firestore document with the same settings
      const docRef = doc(this.db, 'user_transcripts', this.transcriptId);
      await updateDoc(docRef, {
        settings: stateToSave.settings,
        lastModified: new Date()
      });

      // Update local state
      this.isReverted = false;
      this.lastLoadPath = 'edited';
      this.metadata.lastSaved = Date.now();
      this.currentState = stateToSave;
      
      // Update cache with complete state including settings
      await this.saveToCache(stateToSave);
    } catch (error) {
      console.error('Error saving transcript:', error);
      throw error;
    }
  }

  private async loadOriginalState(): Promise<TranscriptState> {
    if (!this.metadata) throw new Error('Metadata not initialized');
    
    try {
      const originalRef = ref(this.storage, this.metadata.paths.original);
      const originalBytes = await getBytes(originalRef);
      const originalData = JSON.parse(new TextDecoder().decode(originalBytes));
      
      // Get current settings from Firestore
      const docRef = doc(this.db, 'user_transcripts', this.transcriptId);
      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();
      
      const state = {
        segments: originalData.segments,
        speakerNames: originalData.speakerNames || {},
        settings: docData?.settings || {
          showSpeakers: true,
          showTimestamps: true,
          concatLevel: 1
        },
        timestamp: Date.now()
      };
      
      this.originalState = this.cloneState(state);
      return state;
    } catch (error) {
      console.error('Failed to load original transcript');
      throw error;
    }
  }

  // Add helper method to validate paths
  private validatePaths(paths: { original: string, edit: string }): boolean {
    return (
      paths.original.startsWith('users/') &&
      paths.edit.startsWith('users/') &&
      paths.original.includes('/transcripts/') &&
      paths.edit.includes('/transcripts_edits/')
    );
  }

  async revertToOriginal(): Promise<TranscriptState> {
    const docRef = doc(this.db, 'user_transcripts', this.transcriptId);
    await updateDoc(docRef, { reverted: true });
  
    this.clearCache();
    this.undoStack = [];
    this.redoStack = [];
    this.currentState = null;
    this.originalState = null;
    this.initialized = false;
    this.isReverted = true;
    this.lastLoadPath = null;
  
    const originalState = await this.loadOriginalState();
    this.currentState = this.cloneState(originalState);
    this.initialized = true;
    this.lastLoadPath = 'original';
  
    return this.currentState;
  }

  private async saveToCache(state: TranscriptState): Promise<void> {
    if (!this.metadata || this.isReverted) return;

    try {
      const cacheData = {
        version: this.metadata.version,
        state: this.cloneState(state),
        metadata: this.metadata,
        timestamp: Date.now()
      };

      localStorage.setItem(
        `${LOCAL_STORAGE_PREFIX}${this.transcriptId}`,
        JSON.stringify(cacheData)
      );
    } catch (error) {
      console.error('Error saving to cache:', error);
    }
  }

  private async loadFromCache(): Promise<TranscriptState | null> {
    if (this.isReverted) return null;
    
    try {
      const cached = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${this.transcriptId}`);
      if (!cached) return null;
      
      const parsed = JSON.parse(cached);
      if (parsed.version !== CACHE_VERSION) {
        this.clearCache();
        return null;
      }
      
      return parsed.state;
    } catch (error) {
      console.error('Error loading from cache:', error);
      return null;
    }
  }

  private clearCache(): void {
    localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${this.transcriptId}`);
  }

  pushState(state: TranscriptState, description: string): void {
    if (!this.initialized || this.isReverted) return;
    
    if (!this.currentState || this.hasStateChanged(state)) {
      if (this.currentState) {
        this.undoStack.push({
          state: this.cloneState(this.currentState),
          description
        });
      }
      
      this.redoStack = [];
      this.currentState = this.cloneState(state);
      
      if (this.undoStack.length > this.maxStackSize) {
        this.undoStack.shift();
      }
      
      this.saveToCache(state);
    }
  }

  async undo(): Promise<TranscriptState | null> {
    if (!this.initialized || this.isReverted || this.undoStack.length === 0 || !this.currentState) return null;

    const lastEntry = this.undoStack.pop()!;
    this.redoStack.push({
      state: this.cloneState(this.currentState),
      description: 'Undo: ' + lastEntry.description
    });
    
    this.currentState = this.cloneState(lastEntry.state);
    await this.saveToCache(lastEntry.state);
    return lastEntry.state;
  }

  async redo(): Promise<TranscriptState | null> {
    if (!this.initialized || this.isReverted || this.redoStack.length === 0 || !this.currentState) return null;

    const nextEntry = this.redoStack.pop()!;
    this.undoStack.push({
      state: this.cloneState(this.currentState),
      description: 'Redo: ' + nextEntry.description
    });
    
    this.currentState = this.cloneState(nextEntry.state);
    await this.saveToCache(nextEntry.state);
    return nextEntry.state;
  }

  private hasStateChanged(newState: TranscriptState): boolean {
    if (!this.currentState) return true;
    
    return (
        JSON.stringify(this.currentState.segments) !== JSON.stringify(newState.segments) ||
        JSON.stringify(this.currentState.speakerNames) !== JSON.stringify(newState.speakerNames) ||
        JSON.stringify(this.currentState.settings) !== JSON.stringify(newState.settings)
    );
  }

  private cloneState(state: TranscriptState): TranscriptState {
    return {
        segments: JSON.parse(JSON.stringify(state.segments)),
        speakerNames: JSON.parse(JSON.stringify(state.speakerNames)),
        settings: JSON.parse(JSON.stringify(state.settings)),
        timestamp: state.timestamp
    };
  }
  canUndo(): boolean {
    return this.initialized && !this.isReverted && this.undoStack.length > 0;
  }

  canRedo(): boolean {
    return this.initialized && !this.isReverted && this.redoStack.length > 0;
  }

  hasUnsavedChanges(): boolean {
    if (this.isReverted) {
      return false;
    }
    
    return true;
  }
}