import React from 'react';
import { Box, Toolbar, useTheme, Tooltip, Typography, CircularProgress, useMediaQuery, Fade } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentIcon from '@mui/icons-material/Payment';
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ProgressStep = ({ 
  icon: Icon, 
  isActive, 
  isCompleted, 
  isLast = false,
  label,
  description,
  isProcessing = false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const StepContent = () => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: { xs: 0.5, sm: 1 },
    }}>
      <Box sx={{ 
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {isActive && isProcessing && (
          <CircularProgress
            size={isMobile ? 42 : 52}
            thickness={2}
            sx={{
              position: 'absolute',
              color: 'rgba(249, 192, 245, 0.3)',
              zIndex: 1
            }}
          />
        )}
        <Box
          sx={{
            width: isMobile ? (isActive ? 38 : 32) : (isActive ? 58 : 55),
            height: isMobile ? (isActive ? 38 : 32) : (isActive ? 58 : 55),
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: isCompleted 
              ? theme.palette.primary.main
              : isActive 
                ? theme.palette.primary.main
                : '#757575',
            transition: 'all 0.3s ease',
            backgroundColor: isCompleted 
            ? 'white'
            : isActive 
              ? 'white'
              : '#fbf9f9',
            boxShadow: isActive 
              ? '0 2px 4px rgba(0, 0, 0, 0.1)'
              : 'none',
            borderBottom: '4px solid',
            borderBottomColor: isCompleted 
              ? theme.palette.primary.main
              : isActive 
                ? theme.palette.primary.main
                : '#e0e0e0',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            },
            zIndex: 2
          }}
        >
          <Icon sx={{ 
            fontSize: isMobile ? (isActive ? 22 : 18) : (isActive ? 36 : 30),
            transition: 'all 0.3s ease'
          }} />
        </Box>
      </Box>

      {!isLast && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          color: isCompleted 
            ? 'rgba(249, 192, 245, 0.5)'
            : '#e0e0e0'
        }}>
          <ArrowForwardIosIcon sx={{ 
            fontSize: 14,
            opacity: 0.8,
            marginLeft: -1
          }} />
          <ArrowForwardIosIcon sx={{ 
            fontSize: 14,
            opacity: 0.6,
            marginLeft: -1.5
          }} />
          <ArrowForwardIosIcon sx={{ 
            fontSize: 14,
            opacity: 0.4,
            marginLeft: -1.5
          }} />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      position: 'relative'
    }}>
      <Tooltip
        title={
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {label}
            </Typography>
            <Typography variant="body2">
              {description}
            </Typography>
          </Box>
        }
        arrow
        placement="bottom"
      >
        <Box>
          <StepContent />
        </Box>
      </Tooltip>
    </Box>
  );
};

const ProgressTracker = ({ currentStep, isProcessing = false }) => {
  const steps = [
    { 
      icon: UploadFileIcon, 
      label: 'Upload',
      description: 'Upload your audio or video file'
    },
    { 
      icon: SettingsIcon, 
      label: 'Settings',
      description: 'Choose language and transcription options'
    },
    { 
      icon: PaymentIcon, 
      label: 'Payment',
      description: 'Select payment method and complete purchase'
    },
    { 
      icon: DescriptionIcon, 
      label: 'Transcribing',
      description: 'We are turning your file to:text'
    }
  ];
  
  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '800px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 1
    }}>
      {/* Progress Steps - Always Centered */}
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'center',
        gap: { xs: 1, sm: 2, md: 3 },
        width: '100%',
        maxWidth: { xs: '100%', md: '75%' },
      }}>
        {steps.map((step, index) => (
          <ProgressStep
            key={step.label}
            icon={step.icon}
            label={step.label}
            description={step.description}
            isActive={currentStep === index}
            isCompleted={currentStep > index}
            isLast={index === steps.length - 1}
            isProcessing={isProcessing && currentStep === index}
          />
        ))}
      </Box>

      {/* Current Stage Headline - Below on Mobile */}
      <Box sx={{
        width: '100%',
        textAlign: 'center',
        mt: { xs: 0.5, md: 0 }
      }}>
        <Fade in timeout={450}>
          <Box>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                fontSize: '0.7rem',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              Step {currentStep + 1} of {steps.length}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '1rem', sm: '1.2rem' },
                color: 'text.primary',
                mt: 0.25,
                lineHeight: 1.2,
              }}
            >
              {steps[currentStep].label}
            </Typography>
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

export const ProgressToolbar = ({ currentStep, isProcessing }) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        bgcolor: 'background.default',
        width: '100%',
        backdropFilter: 'blur(8px)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '1px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Toolbar
        sx={{
          minHeight: { 
            xs: '88px !important',  // Adjusted for two rows on mobile
            md: '64px !important'   // Single row on desktop
          },
          px: { xs: 1.5, sm: 2 },
          py: { xs: 1, md: 1 },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ProgressTracker currentStep={currentStep} isProcessing={isProcessing} />
      </Toolbar>
    </Box>
  );
};

export default ProgressToolbar;