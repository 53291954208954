import React, { useState, useContext } from 'react';
import { AuthContext, FireactContext, SetPageTitle } from '@fireactjs/core';
import {
  Container,
  Paper,
  Box,
  Avatar,
  Typography,
  List,
  ListItem,
  Divider,
  Grid,
  Alert,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendEmailVerification } from 'firebase/auth';

export const UserProfile = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [sendVerification, setSendVerification] = useState({
    'success': false,
    'error': null
  });
  const { config } = useContext(FireactContext);
  const pathnames = config.pathnames;

  const getDisplayName = (user) => {
    if (user.displayName && user.displayName.trim() !== '') {
      return user.displayName;
    }
    return user.email ? user.email.split('@')[0] : 'User';
  };

  const ProfileSection = ({ label, value, onClick, icon, error }) => (
    <>
      <ListItem
        sx={{
          px: 3, // Consistent padding
          py: 2,
          '&:hover': {
            bgcolor: 'rgba(249, 192, 245, 0.1)',
            cursor: 'pointer'
          }
        }}
        onClick={onClick}
      >
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography 
              variant="subtitle2" 
              color="textSecondary" 
              sx={{ mb: 0.5 }}
            >
              {label}
            </Typography>
            <Typography 
              variant="body1" 
              color={error ? "error" : "textPrimary"}
            >
              {value}
            </Typography>
          </Grid>
          <Grid item>
            {icon && (
              <IconButton 
                size="small" 
                sx={{ 
                  color: error ? "error.main" : "primary.main"
                }}
              >
                {icon}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );

  return (
    <AuthContext.Consumer>
      {context => (
        <Container maxWidth="md">
          <SetPageTitle title="User Profile" />
          
          {/* Alerts */}
          {(sendVerification.error || sendVerification.success) && (
            <Box mb={2}>
              {sendVerification.error && (
                <Alert severity="error">
                  {sendVerification.error}
                </Alert>
              )}
              {sendVerification.success && (
                <Alert severity="success">
                  Please check your email inbox to verify the email address. 
                  Refresh this page after you verified your email address.
                </Alert>
              )}
            </Box>
          )}

          {/* Profile Card */}
          <Box 
            sx={{ 
              position: 'relative',
              pt: 4 // Space for avatar
            }}
          >
            {/* Avatar */}
            <Avatar 
              sx={{ 
                width: 56,
                height: 56,
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                position: 'absolute',
                left: 24,
                transform: 'translateY(-50%)',
                border: '3px solid white',
                boxShadow: theme => theme.shadows[2],
                zIndex: 1
              }}
            >
              {context.authUser?.user?.email?.[0].toUpperCase()}
            </Avatar>

            <Paper 
              elevation={2}
              sx={{ 
                borderRadius: 2,
                overflow: 'hidden'
              }}
            >
              {/* Header */}
              <Box 
                sx={{
                  bgcolor: '#fbf9f9',
                  px: 3,
                  py: 2,
                  pl: 12, // Space after avatar
                  borderBottom: '2px solid rgba(0, 0, 0, 0.12)',

                }}
              >
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: 'primary.contrastText',
                    mb: 0.5
                  }}
                >
                  {getDisplayName(context.authUser.user)}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'primary.contrastText',
                    opacity: 0.8
                  }}
                >
                  {context.authUser.user.email}
                </Typography>
              </Box>

              {/* Profile Sections */}
              <List disablePadding>
                <ProfileSection
                  label="NAME"
                  value={context.authUser.user.displayName || 'Not set'}
                  onClick={() => navigate(pathnames.UserUpdateName)}
                  icon={<EditIcon />}
                />

                <ProfileSection
                  label="EMAIL"
                  value={context.authUser.user.email}
                  onClick={() => navigate(pathnames.UserUpdateEmail)}
                  icon={<EditIcon />}
                />

                <ProfileSection
                  label="EMAIL VERIFICATION"
                  value={context.authUser.user.emailVerified ? "Verified" : "Unverified"}
                  onClick={() => {
                    if (!context.authUser.user.emailVerified) {
                      setSendVerification({ 'success': false, 'error': null });
                      sendEmailVerification(auth.currentUser)
                        .then(() => {
                          setSendVerification({ 'success': true, 'error': null });
                        })
                        .catch(error => {
                          const errorMessage = error.code === "auth/too-many-requests"
                            ? "We have blocked all requests from this device due to unusual activity. Try again later."
                            : error.message;
                          setSendVerification({ 'success': false, 'error': errorMessage });
                        });
                    }
                  }}
                  icon={context.authUser.user.emailVerified ? <VerifiedUserIcon /> : <SendIcon />}
                  error={!context.authUser.user.emailVerified}
                />

                <ProfileSection
                  label="PASSWORD"
                  value="••••••••"
                  onClick={() => navigate(pathnames.UserUpdatePassword)}
                  icon={<EditIcon />}
                />
              </List>

              {/* Delete Account */}
              <ListItem
                onClick={() => navigate(pathnames.UserDelete)}
                sx={{
                  px: 3,
                  py: 2,
                  '&:hover': {
                    bgcolor: 'error.light',
                    cursor: 'pointer'
                  }
                }}
              >
                <Typography 
                  color="error" 
                  fontWeight="medium"
                >
                  DELETE ACCOUNT
                </Typography>
              </ListItem>
            </Paper>
          </Box>
        </Container>
      )}
    </AuthContext.Consumer>
  );
};

export default UserProfile;