// src/pages/TranscriptPage.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getStorage, ref, getBytes } from 'firebase/storage';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Card } from '../components/ui/Card';
import { Loader } from '../components/common/Loader';
import TranscriptEditor from '../components/transcript_editor/TranscriptEditor';
import TranscriptResume from '../components/transcript_resume/TranscriptResume';
import TranscriptNavbar from '../components/transcript_common/TranscriptNavbar';
import { Box } from '@mui/material';

export const EditTranscriptPage = () => {
  const { transcriptId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [toolbarContent, setToolbarContent] = useState(null);
  const [currentView, setCurrentView] = useState('editor');
  const [transcriptName, setTranscriptName] = useState('');
  // We always allow saving
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();
  const editorRef = useRef(null);

  const fetchTranscriptData = useCallback(async () => {
    if (!auth.currentUser) {
      setError('Please sign in to access this page');
      setLoading(false);
      return;
    }

    try {
      // Get transcript document from Firestore
      const transcriptDocRef = doc(db, 'user_transcripts', transcriptId);
      const transcriptDoc = await getDoc(transcriptDocRef);

      if (!transcriptDoc.exists()) {
        throw new Error('Transcript not found');
      }

      const transcriptData = transcriptDoc.data();
      setTranscriptName(transcriptData.transcript_name || 'Untitled Transcript');
      
      // Validate paths and ownership
      const pathUserId = transcriptData.audio_uri.split('/')[1];
      if (pathUserId !== auth.currentUser.uid) {
        throw new Error('Unauthorized access');
      }

      // Get audio file using Firebase SDK
      const audioRef = ref(storage, transcriptData.audio_uri);
      const audioBytes = await getBytes(audioRef);
      const audioBlob = new Blob([audioBytes], { type: 'audio/mpeg' });
      const blobUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(blobUrl);

      // Initialize transcript data structure
      setTranscript({
        audio_uri: transcriptData.audio_uri,
        transcript_path: transcriptData.transcript_path
      });

      setLoading(false);
      return blobUrl;  // Return the URL for cleanup
    } catch (err) {
      console.error('Error fetching transcript:', err);
      setError(err.message || 'Error loading transcript');
      setLoading(false);
      return null;
    }
  }, [auth.currentUser, transcriptId, db, storage]);

  useEffect(() => {
    let blobUrl = null;

    const initializeTranscript = async () => {
      blobUrl = await fetchTranscriptData();
    };

    initializeTranscript();

    // Cleanup function to revoke blob URL
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [fetchTranscriptData]);

  // Handle save
  const handleSave = async () => {
    try {
      if (editorRef.current) {
        await editorRef.current.handleSave();
      }
    } catch (error) {
      console.error('Error saving transcript:', error);
      throw error;
    }
  };

  // Handle revert
  const handleRevert = async () => {
    setLoading(true);
    try {
      if (editorRef.current) {
        await editorRef.current.handleRevert();
        await fetchTranscriptData();
      }
    } catch (error) {
      console.error('Error reverting transcript:', error);
      setError('Error reverting transcript');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box className="flex items-center justify-center h-full">
        <Card>
          <Box className="flex justify-center items-center h-64">
            <Loader size="large" fullscreen text="Loading transcript" />
          </Box>
        </Card>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="flex items-center justify-center h-full">
        <Card>
          <Box className="text-red-500 text-center p-4">
            <p>{error}</p>
          </Box>
        </Card>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      position: 'relative'
    }}>
      <Box sx={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
        background: 'rgba(255,255,255,0.8)',
        width: '100%',
      }}>
        <TranscriptNavbar 
          transcriptId={transcriptId}
          segments={transcript?.segments || []}
          transcriptName={transcriptName}
          currentView={currentView}
          onViewChange={setCurrentView}
          onSave={handleSave}
          onRevert={handleRevert}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        {currentView === 'editor' && toolbarContent}
      </Box>
      <Box sx={{ 
        flexGrow: 1, 
        overflow: 'hidden',
        '& > .MuiCard-root': {
          height: '100%',
          '& > .MuiCardContent-root': {
            height: '100%',
            padding: 0,
            '&:last-child': {
              paddingBottom: 0
            }
          }
        }
      }}>
        <Card>
          {transcript && audioUrl && currentView === 'editor' && (
            <TranscriptEditor
              ref={editorRef}
              audioUrl={audioUrl}
              transcriptId={transcriptId}
              setToolbarContent={setToolbarContent}
            />
          )}
          {transcript && currentView === 'resume' && (
            <TranscriptResume
              transcriptId={transcriptId}
            />
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default EditTranscriptPage;