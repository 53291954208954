// src/components/transcript/utils.ts
import { InputSegment, DisplaySegment } from './types';

export const createDisplaySegment = (segments: InputSegment[]): DisplaySegment => {
  return {
    segments,
    speaker: segments[0].speaker,
    start: segments[0].start,
    end: segments[segments.length - 1].end,
    text: segments.map(s => s.text).join(' ')
  };
};

export const groupSegments = (segments: InputSegment[], level: number): DisplaySegment[] => {
  const displaySegments: DisplaySegment[] = [];
  
  if (level === 0) {
    // Lowest level: each segment on its own
    return segments.map(segment => createDisplaySegment([segment]));
  } else if (level === 3) {
    // Highest level: group by speaker
    let currentSegments: InputSegment[] = [];
    
    segments.forEach((segment) => {
      if (currentSegments.length === 0 || segment.speaker === currentSegments[0].speaker) {
        currentSegments.push(segment);
      } else {
        displaySegments.push(createDisplaySegment(currentSegments));
        currentSegments = [segment];
      }
    });
    
    if (currentSegments.length > 0) {
      displaySegments.push(createDisplaySegment(currentSegments));
    }
  } else {
    // Middle levels: group by number of segments
    const segmentsPerGroup = Math.pow(2, level);
    let currentSegments: InputSegment[] = [];
    
    segments.forEach((segment, index) => {
      currentSegments.push(segment);
      
      if (currentSegments.length >= segmentsPerGroup || 
          segment.speaker !== segments[index + 1]?.speaker ||
          index === segments.length - 1) {
        displaySegments.push(createDisplaySegment(currentSegments));
        currentSegments = [];
      }
    });
  }
  
  return displaySegments;
};

const SPEAKER_COLORS = [
  "#FFB3BA",  // Pastel Pink
  "#BAFFC9",  // Pastel Green
  "#BAE1FF",  // Pastel Blue
  "#FFFFBA",  // Pastel Yellow
  "#FFDFBA",  // Pastel Orange
  "#E0BBE4",  // Pastel Purple
  "#D4F0F0",  // Pastel Turquoise
  "#FFC6FF",  // Pastel Magenta
  "#DAEAF6",  // Pastel Sky Blue
  "#FFE5B4"   // Pastel Peach
];

export const generatePastelColor = (index: number): string => {
  // Use modulo to cycle through the colors if index exceeds array length
  return SPEAKER_COLORS[Math.abs(index) % SPEAKER_COLORS.length];
};

export const getTotalColors = (): number => {
  return SPEAKER_COLORS.length;
};

export const getAllColors = (): string[] => {
  return [...SPEAKER_COLORS];
};

export const speakerToPersonName = (speaker: string): string => {
  const speakerNum = parseInt(speaker.replace('SPEAKER_', ''));
  return `Person ${speakerNum + 1}`;
};

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};