import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { primaryColor } from '../../config/theme';
import { Logo } from './Logo';

export const Loader = ({ 
  size = "medium", 
  fullscreen = false,
  text = null
}) => {
  const getCircleSize = () => {
    switch(size) {
      case "small": return "48px";
      case "medium": return "64px";
      case "large": return "96px";
      default: return "64px";
    }
  };

  const getLogoSize = () => {
    switch(size) {
      case "small": return "28px";
      case "medium": return "36px";
      case "large": return "56px";
      default: return "36px";
    }
  };

  const getTextSize = () => {
    switch(size) {
      case "small": return "0.875rem";
      case "medium": return "1rem";
      case "large": return "1.25rem";
      default: return "1rem";
    }
  };

  const LoaderContent = () => (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <Box 
        sx={{ 
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: getCircleSize(),
          height: getCircleSize()
        }}
      >
        <CircularProgress 
          sx={{ 
            color: primaryColor,
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1
          }}
          size={getCircleSize()} 
        />
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            zIndex: 2
          }}
        >
          <Logo size={getLogoSize()} />
        </Box>
      </Box>
      
      {text && (
        <Typography 
          variant="body1" 
          sx={{ 
            textAlign: 'center',
            fontSize: getTextSize(),
            opacity: 0.9,
            whiteSpace: 'nowrap'
          }}
        >
          {text}
        </Typography>
      )}
    </Box>
  );

  if (fullscreen) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          zIndex: 9999
        }}
      >
        <LoaderContent />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: '100%'
      }}
    >
      <LoaderContent />
    </Box>
  );
};

export default Loader;