import React, { useContext, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Drawer as MuiDrawer, IconButton, Divider, Typography, Button } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useNavigate } from "react-router-dom";
import { FireactContext } from "@fireactjs/core";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#fbf9f9',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: '#fbf9f9',
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#fbf9f9',
  '& .MuiDrawer-paper': {
    backgroundColor: '#fbf9f9',
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  width: `calc(${theme.spacing(9)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const AppTemplate = ({ logo, drawerMenu }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const {config} = useContext(FireactContext);
    const brand = config.brand;
    const navigate = useNavigate();
    
    // Create cloned drawer menu with open prop
    const DrawerMenuWithOpenState = React.cloneElement(drawerMenu, { isDrawerOpen: open });

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    {open && (
                        <div style={{
                            marginLeft: '0px',
                            marginRight: 'auto',
                            display: 'inline-flex',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}>
                            <Button
                                style={{ color: "#000000" }}
                                onClick={() => navigate("/")}
                            >
                                <div style={{
                                    display: 'inline-flex',
                                    paddingRight: '20px'
                                }}>
                                    {logo}
                                </div>
                                <Typography variant="h6">{brand}</Typography>
                            </Button>
                        </div>
                    )}
                    <IconButton
                        onClick={() => setOpen(!open)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            transition: theme.transitions.create(['transform', 'margin'], {
                                duration: theme.transitions.duration.shorter,
                            }),
                            ...(open && {
                                marginRight: 0,
                            }),
                            ...(!open && {
                                marginLeft: '4px',
                                transform: 'rotate(180deg)',
                            }),
                        }}
                    >
                        {open ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {DrawerMenuWithOpenState}
                <Divider />
            </Drawer>
            <Box component="main" sx={{
                flexGrow: 1,
                backgroundColor: 'white',
                height: '100vh',
                overflow: 'auto'
            }}>
                <div style={{ position: 'relative' }}>
                    <Box>
                        <Outlet />
                    </Box>
                </div>
            </Box>
        </Box>
    );
}

export default AppTemplate;