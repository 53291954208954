import React, { useState, useCallback, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Card } from '../ui/Card';
import { TranscriptEditorProps, InputSegment, HistoryState, TranscriptEditorRef } from './types';
import { groupSegments, generatePastelColor } from './utils';
import EditableSegment from './EditableSegment';
import TranscriptToolbar from './TranscriptToolbar';
import Loader from '../common/Loader';
import { TranscriptHistoryManager } from './TranscriptHistoryManager';
import { Box } from '@mui/material';

import './styles.css';

const TranscriptEditor = forwardRef<TranscriptEditorRef, TranscriptEditorProps>(({
  audioUrl,
  transcriptId,
  setToolbarContent
}, ref) => {
  const [segments, setSegments] = useState<InputSegment[]>([]);
  const [displaySettings, setDisplaySettings] = useState({
    showSpeakers: true,
    showTimestamps: true,
    concatLevel: 1
  });
  const [speakerNames, setSpeakerNames] = useState<Record<string, string>>({});
  const [activeSegmentIndex, setActiveSegmentIndex] = useState<number | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isReverted, setIsReverted] = useState(false);

  const [historyState, setHistoryState] = useState<HistoryState>({
    canUndo: false,
    canRedo: false
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const scrollableContentRef = useRef<HTMLDivElement>(null);
  const historyManagerRef = useRef<TranscriptHistoryManager>(new TranscriptHistoryManager(transcriptId));

  useEffect(() => {
    if (isReverted) {
      setIsReverted(false);
      return;
    }
  
    const initializeEditor = async () => {
      setIsLoading(true);
      try {
        const state = await historyManagerRef.current.initialize();
        setSegments(state.segments);
        setSpeakerNames(state.speakerNames);
        setDisplaySettings({
          showSpeakers: state.settings.showSpeakers,
          showTimestamps: state.settings.showTimestamps,
          concatLevel: state.settings.concatLevel,
        });
  
        setHistoryState({
          canUndo: historyManagerRef.current.canUndo(),
          canRedo: historyManagerRef.current.canRedo(),
        });
        setHasUnsavedChanges(historyManagerRef.current.hasUnsavedChanges());
      } catch (error) {
        setError('Failed to load transcript');
      } finally {
        setIsLoading(false);
      }
    };
  
    initializeEditor();
  }, [transcriptId, isReverted]);

  useImperativeHandle(ref, () => ({
    handleSave: async () => {
      try {
        await historyManagerRef.current.saveToFirebase();
        setHasUnsavedChanges(false);
      } catch (error) {
        throw error;
      }
    },
    handleRevert: async () => {
      try {
        const state = await historyManagerRef.current.revertToOriginal();
        setSegments(state.segments);
        setSpeakerNames(state.speakerNames);
        setDisplaySettings(state.settings);
        setHistoryState({ canUndo: false, canRedo: false });
        setHasUnsavedChanges(false);
      } catch (error) {
        throw error;
      }
    }
  }));

  const pushStateToHistory = useCallback((
    newSegments: InputSegment[],
    newSpeakerNames: Record<string, string>,
    newSettings: typeof displaySettings,
    description: string
  ) => {
    historyManagerRef.current.pushState({
      segments: newSegments,
      speakerNames: newSpeakerNames,
      settings: newSettings,
      timestamp: Date.now()
    }, description);

    setHistoryState({
      canUndo: historyManagerRef.current.canUndo(),
      canRedo: historyManagerRef.current.canRedo()
    });
    setHasUnsavedChanges(historyManagerRef.current.hasUnsavedChanges());
  }, []);

  const handleUndo = useCallback(async () => {
    const state = await historyManagerRef.current.undo();
    if (state) {
      setSegments(state.segments);
      setSpeakerNames(state.speakerNames);
      setDisplaySettings(state.settings);
      
      setHistoryState({
        canUndo: historyManagerRef.current.canUndo(),
        canRedo: historyManagerRef.current.canRedo()
      });
      setHasUnsavedChanges(historyManagerRef.current.hasUnsavedChanges());
    }
  }, []);

  const handleRedo = useCallback(async () => {
    const state = await historyManagerRef.current.redo();
    if (state) {
      setSegments(state.segments);
      setSpeakerNames(state.speakerNames);
      setDisplaySettings(state.settings);
      
      setHistoryState({
        canUndo: historyManagerRef.current.canUndo(),
        canRedo: historyManagerRef.current.canRedo()
      });
      setHasUnsavedChanges(historyManagerRef.current.hasUnsavedChanges());
    }
  }, []);

  const handleTextChange = useCallback((newText: string, start: number, end: number) => {
    setSegments(prevSegments => {
      const newSegments = [...prevSegments];
      const segmentIndex = newSegments.findIndex(
        seg => seg.start === start && seg.end === end
      );
      
      if (segmentIndex >= 0) {
        newSegments[segmentIndex] = {
          ...newSegments[segmentIndex],
          text: newText.trim()
        };
        
        pushStateToHistory(
          newSegments,
          speakerNames,
          displaySettings,
          `Edit segment ${segmentIndex}`
        );
      }
      return newSegments;
    });
  }, [speakerNames, displaySettings, pushStateToHistory]);

  const handleSpeakerNameChange = useCallback((speaker: string, newName: string) => {
    setSpeakerNames(prev => {
      const newSpeakerNames = { ...prev, [speaker]: newName };
      pushStateToHistory(
        segments,
        newSpeakerNames,
        displaySettings,
        `Rename speaker ${speaker}`
      );
      return newSpeakerNames;
    });
  }, [segments, displaySettings, pushStateToHistory]);

  const handleToggleSpeakers = useCallback(() => {
    setDisplaySettings(prev => {
      const newSettings = { ...prev, showSpeakers: !prev.showSpeakers };
      pushStateToHistory(
        segments,
        speakerNames,
        newSettings,
        'Toggle speakers'
      );
      return newSettings;
    });
  }, [segments, speakerNames, pushStateToHistory]);

  const handleToggleTimestamps = useCallback(() => {
    setDisplaySettings(prev => {
      const newSettings = { ...prev, showTimestamps: !prev.showTimestamps };
      pushStateToHistory(
        segments,
        speakerNames,
        newSettings,
        'Toggle timestamps'
      );
      return newSettings;
    });
  }, [segments, speakerNames, pushStateToHistory]);

  const handleConcatLevelChange = useCallback((newLevel: number) => {
    setDisplaySettings(prev => {
      const newSettings = { ...prev, concatLevel: newLevel };
      pushStateToHistory(
        segments,
        speakerNames,
        newSettings,
        'Change concat level'
      );
      return newSettings;
    });
  }, [segments, speakerNames, pushStateToHistory]);

  const speakerColors = React.useMemo(() => {
    const colors: Record<string, string> = {};
    const processedSpeakers = new Set<string>();
    
    segments.forEach(segment => {
      if (segment?.speaker && !processedSpeakers.has(segment.speaker)) {
        colors[segment.speaker] = generatePastelColor(processedSpeakers.size);
        processedSpeakers.add(segment.speaker);
      }
    });
    
    return colors;
  }, [segments]);

  const displayedSegments = React.useMemo(() => 
    groupSegments(segments, displaySettings.concatLevel),
    [segments, displaySettings.concatLevel]
  );

  const handleTimeUpdate = useCallback((time: number) => {
    const index = displayedSegments.findIndex(
      segment => time >= segment.start && time <= segment.end
    );
    setActiveSegmentIndex(index >= 0 ? index : null);
  }, [displayedSegments]);

  useEffect(() => {
    setToolbarContent(
      <TranscriptToolbar
        audioUrl={audioUrl}
        audioRef={audioRef}
        onTimeUpdate={handleTimeUpdate}
        showSpeakers={displaySettings.showSpeakers}
        showTimestamps={displaySettings.showTimestamps}
        onToggleSpeakers={handleToggleSpeakers}
        onToggleTimestamps={handleToggleTimestamps}
        concatLevel={displaySettings.concatLevel}
        onConcatLevelChange={handleConcatLevelChange}
        segments={displayedSegments}
        activeSegmentIndex={activeSegmentIndex}
        speakerColors={speakerColors}
        onUndo={handleUndo}
        onRedo={handleRedo}
        historyState={historyState}
        hasUnsavedChanges={hasUnsavedChanges}
      />
    );
  }, [
    audioUrl,
    displaySettings,
    displayedSegments,
    activeSegmentIndex,
    speakerColors,
    historyState,
    hasUnsavedChanges,
    handleTimeUpdate,
    handleToggleSpeakers,
    handleToggleTimestamps,
    handleConcatLevelChange,
    handleUndo,
    handleRedo,
    setToolbarContent
  ]);

  const renderedSegments = displayedSegments.map((segment, index) => (
    <EditableSegment
      key={`${segment.start}-${segment.end}`}
      segment={segment}
      showSpeakers={displaySettings.showSpeakers}
      showTimestamps={displaySettings.showTimestamps}
      speakerColor={speakerColors[segment.speaker] || '#BAE1FF'}
      showSpeakerLabel={
        index === 0 || 
        segment.speaker !== displayedSegments[index - 1]?.speaker
      }
      isPlaying={index === activeSegmentIndex && isPlaying}
      currentTime={audioRef.current.currentTime}
      onPlay={() => {
        setActiveSegmentIndex(index);
        setIsPlaying(true);
        audioRef.current.currentTime = segment.start;
        audioRef.current.play();
      }}
      onPause={() => {
        setIsPlaying(false);
        audioRef.current.pause();
      }}
      onTextChange={handleTextChange}
      onSpeakerNameChange={handleSpeakerNameChange}
      customSpeakerName={speakerNames[segment.speaker]}
    />
  ));

  if (isLoading) {
    return (
      <Box className="flex items-center justify-center h-full">
        <Card>
          <Box className="flex justify-center items-center h-64">
            <Loader size="small" fullscreen={false} text="Loading transcript..."/>
          </Box>
        </Card>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="flex items-center justify-center h-full">
        <Card>
          <Box className="text-red-500 text-center p-4">
            <p>{error}</p>
          </Box>
        </Card>
      </Box>
    );
  }

  return (
    <div className="w-full h-full">
      <Card className="w-full h-full">
        <div className="transcript-editor">
          <div 
            className="scrollable-content"
            ref={scrollableContentRef}
          >
            {renderedSegments}
          </div>
        </div>
      </Card>
    </div>
  );
});

export default TranscriptEditor;