// src/pages/PreviewPage.js
import React, { useState, useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { PreviewFileUploader } from '../components/preview/PreviewFileUploader';
import { PreviewTranscriptDisplay } from '../components/preview/PreviewTranscriptDisplay';
import { Loader } from '../components/common/Loader';
import { transcribePreview } from '../api/runpod';
import { getStorage, ref, getBytes } from 'firebase/storage';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const PreviewPage = () => {
  const [transcriptId, setTranscriptId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [transcriptData, setTranscriptData] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);

  const db = getFirestore();
  const storage = getStorage();

  // Load transcript data when available
  useEffect(() => {
    const loadTranscriptData = async () => {
      if (!transcriptId) return;

      try {
        // Get transcript details from Firestore
        const transcriptRef = doc(db, 'user_transcripts', transcriptId);
        const docSnap = await getDoc(transcriptRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          
          if (data.text_gcs_uri) {
            // Get transcript content from Storage
            const textRef = ref(storage, data.text_gcs_uri);
            const textBytes = await getBytes(textRef);
            const textContent = new TextDecoder().decode(textBytes);
            setTranscriptData(JSON.parse(textContent));
            setFileDetails(data);
          }
        }
      } catch (error) {
        console.error('Error loading transcript:', error);
        setError('Failed to load transcript');
      }
    };

    loadTranscriptData();
  }, [transcriptId, db, storage]);

  const handleUploadComplete = async (uploadData) => {
    setIsProcessing(true);
    setError(null);
    setTranscriptId(uploadData.transcriptId);
    
    try {
      const result = await transcribePreview({
        gcsUri: uploadData.gcsUri,
        onProgress: (status) => {
          // Handle progress updates if needed
          console.log('Progress:', status);
        }
      });

      if (result.success) {
        setTranscriptData(result.transcript);
        setFileDetails({
          ...uploadData,
          price: result.price || 1.99 // Default preview price
        });
      } else {
        throw new Error('Transcription failed');
      }

    } catch (error) {
      console.error('Processing error:', error);
      setError(error.message || 'Failed to process file');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleLogin = () => {
    // Redirect to login with transcript ID
    window.location.href = `${window.location.origin}/login?transcript_id=${transcriptId}`;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4, bgcolor: 'white' }}>
      {/* Main Content */}
      {isProcessing ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
          <Loader size="medium" text="Transcribing your file..." />
        </Box>
      ) : error ? (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography color="error" variant="h6">
            {error}
          </Typography>
        </Box>
      ) : transcriptData ? (
        <PreviewTranscriptDisplay
          transcriptData={transcriptData}
          onLogin={handleLogin}
          filename={fileDetails?.filename}
          price={fileDetails?.price || 1.99}
        />
      ) : (
        <PreviewFileUploader onUploadComplete={handleUploadComplete} />
      )}
    </Container>
  );
};

export default PreviewPage;