import React, { useState } from 'react';
import {Container, Typography, Box, IconButton, InputAdornment, Divider, Button} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { AuthContainer, StyledTextField, StyledButton, GoogleButton, LogoContainer } from './authStyles';

export const CustomSignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleGoogleSignUp = async () => {
    try {
      setLoading(true);
      setError('');
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = () => {
    if (!email) {
      setError('Please enter your email');
      return;
    }
    
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setError('');
    setStep(2);
  };

  const handleEmailSignUp = async () => {
    if (!password) {
      setError('Please enter a password');
      return;
    }

    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }
    
    try {
      setLoading(true);
      setError('');
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (step === 1) {
        handleContinue();
      } else {
        handleEmailSignUp();
      }
    }
  };
  
  const handleBack = () => {
    setStep(1);
    setError('');
  };

  return (
      <Container maxWidth="sm" sx={{ 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'auto',
      }}>
      <AuthContainer sx={{ width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <LogoContainer>
            <img src="/logo.png" alt="ToText Logo" />
            <Typography variant="body2" color="text.secondary">
              Create your account
            </Typography>
          </LogoContainer>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            {step === 1 ? (
              <>
                <GoogleButton
                  fullWidth
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleSignUp}
                  disabled={loading}
                >
                  Continue with Google
                </GoogleButton>

                <Divider>or</Divider>

                <StyledTextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                  error={!!error}
                  onKeyDown={handleKeyDown}
                  autoComplete="username" // Add this
                  InputLabelProps={{ shrink: true }} // Add this
                />

                {error && (
                  <Typography color="error" variant="body2" sx={{ textAlign: 'center' }}>
                    {error}
                  </Typography>
                )}

                <StyledButton
                  fullWidth
                  variant="contained"
                  onClick={handleContinue}
                  disabled={loading}
                >
                  Continue
                </StyledButton>
              </>
            ) : (
              <>
                <StyledTextField
                  fullWidth
                  label="Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                  error={!!error}
                  onKeyDown={handleKeyDown}
                  autoComplete="current-password" // Add this
                  InputLabelProps={{ shrink: true }} // Add this
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {error && (
                  <Typography color="error" variant="body2" sx={{ textAlign: 'center' }}>
                    {error}
                  </Typography>
                )}

                <StyledButton
                  fullWidth
                  variant="contained"
                  onClick={handleEmailSignUp}
                  disabled={loading}
                >
                  {loading ? 'Creating account...' : 'Sign up'}
                </StyledButton>

                <Button
                  onClick={handleBack}
                  sx={{ textTransform: 'none' }}
                >
                  Back to email
                </Button>
              </>
            )}

            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Already have an account? {' '}
              <Link to="/sign-in" style={{ color: 'inherit' }}>
                Log in
              </Link>
            </Typography>
          </Box>
        </Box>
      </AuthContainer>
    </Container>
  );
};