import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { getFirestore, collection, doc, onSnapshot, addDoc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { languageOptions } from '../components/upload/Languages';
import ProgressToolbar from '../components/common/ProgressBar';
import { Loader } from '../components/common/Loader';
import { 
  Container, Box, Typography, Select, FormControl, CircularProgress,
  Paper, Button, Stack, Tooltip, InputLabel
} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const DOMAIN = process.env.REACT_APP_DOMAIN || window.location.origin;
const CREDIT_PRICE_ID = process.env.REACT_APP_CREDIT_PRICE_ID;
const CREDITS_PER_PURCHASE = 20 * 60;
const CREDIT_PACK_PRICE = process.env.REACT_APP_CREDIT_PACK_PRICE || 15;

export const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fileId } = useParams();
  const auth = getAuth();
  const db = getFirestore();
  
  const [fileDetails, setFileDetails] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [loading, setLoading] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [duration, setDuration] = useState(null);
  const [price, setPrice] = useState(null);
  const [userCredits, setUserCredits] = useState(0);
  const [creditsNeeded, setCreditsNeeded] = useState(0);

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const getUserCredits = httpsCallable(functions, 'get_user_credits');
        const result = await getUserCredits();
        
        if (result.data.success) {
          setUserCredits(result.data.credits);
        } else {
          console.warn('Could not fetch user credits:', result.data.error);
          setUserCredits(0);
        }
      } catch (error) {
        console.error('Error fetching user credits:', error);
        setUserCredits(0);
      }
    };

    fetchUserCredits();
  }, []);

  useEffect(() => {
    const initializePage = async () => {
      try {
        if (location.state?.fileData) {
          setFileDetails(location.state.fileData);
        } else {
          // Get the transcript ID from the fileId (removing 'uploads/' prefix if present)
          const transcriptId = fileId.replace('uploads/', '');
          
          // Get the document from Firestore
          const transcriptRef = doc(db, 'user_transcripts', transcriptId);
          const transcriptDoc = await getDoc(transcriptRef);

          if (!transcriptDoc.exists()) {
            throw new Error('Transcript not found');
          }

          // Get the document data
          const data = transcriptDoc.data();

          // Verify user ownership
          if (data.user_id !== auth.currentUser.uid) {
            throw new Error('Access denied');
          }

          // Format the data to match the expected structure
          const fileDetails = {
            transcriptId: transcriptId,
            filename: data.transcript_name,
            status: data.status,
            duration: data.duration,
            price: data.price,
            language: data.language,
            payment_status: data.payment_status,
            created_at: data.created_at,
            last_edited: data.last_edited,
            audio_gcs_uri: data.audio_gcs_uri,
            text_gcs_uri: data.text_gcs_uri,
            text_edit_gcs_uri: data.text_edit_gcs_uri
          };

          setFileDetails(fileDetails);
        }
      } catch (error) {
        console.error('Error initializing page:', error);
        setError(error.message || 'Error loading file details. Please try uploading again.');
      } finally {
        setLoading(false);
      }
    };

    initializePage();
  }, [fileId, location.state, db, auth.currentUser?.uid]);

  useEffect(() => {
    const calculatePricing = async () => {
      if (fileDetails) {
        try {
          console.log('Starting price calculation for transcript:', fileDetails.transcriptId);
          
          // First check if duration and price already exist in Firestore
          const transcriptRef = doc(db, 'user_transcripts', fileDetails.transcriptId);
          const transcriptDoc = await getDoc(transcriptRef);
          
          if (transcriptDoc.exists()) {
            const data = transcriptDoc.data();
            console.log('Found existing transcript document:', data);
            
            if (data.duration && data.price) {
              console.log('Using existing duration and price');
              setDuration(data.duration);
              setPrice(data.price);
              setCreditsNeeded(Math.max(Math.floor(data.duration / 3600), 1));
              setError(null);
              return;
            }
          }

          // Calculate price
          const calculatePrice = httpsCallable(functions, 'calculate_duration_and_price');
          const result = await calculatePrice({ 
            transcriptId: fileDetails.transcriptId
          });
          
          if (!result.data.success) {
            throw new Error(result.data.error || 'Failed to calculate price');
          }
          
          console.log('Calculation successful:', result.data);
          
          setDuration(result.data.duration);
          setPrice(result.data.price);
          setCreditsNeeded(Math.max(Math.floor(result.data.duration / 3600), 1));
          setError(null);

          // Update the Firestore document with price info
          await updateDoc(transcriptRef, {
            duration: result.data.duration,
            price: result.data.price,
            calculatedAt: new Date()
          });
          
        } catch (error) {
          console.error('Error in price calculation:', error);
          setError('Error calculating price. Please try again.');
        }
      }
    };    

    calculatePricing();
  }, [fileDetails, db]);

  const createCheckoutSession = async (payload) => {
    try {
      const userRef = doc(db, 'customers', auth.currentUser.uid);
      const checkoutRef = collection(userRef, 'checkout_sessions');
      
      const sessionDoc = await addDoc(checkoutRef, {
        ...payload,
        success_url: `${DOMAIN}/transcript/${fileDetails.transcriptId}/return`,
        cancel_url: `${DOMAIN}/payment/${fileId}?canceled=true`,
        automatic_tax: { enabled: true },
        tax_id_collection: { enabled: true },
        payment_intent_data: {
          capture_method: 'manual',
        }
      });

      const unsubscribe = onSnapshot(sessionDoc, (snap) => {
        const { error, url } = snap.data() || {};
        if (error) {
          setError(`Payment error: ${error.message}`);
          setPaymentProcessing(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });

      setTimeout(() => unsubscribe(), 120000);
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Error initiating payment. Please try again.');
      setPaymentProcessing(false);
    }
  };

  const processFile = async (transcriptId) => {
    try {
      // Ensure we have the fileId with the correct prefix
      const fullFileId = fileDetails.fileId.startsWith('uploads/') 
        ? fileDetails.fileId 
        : `uploads/${fileDetails.fileId}`;
      
      console.log('Processing file:', fullFileId);
      
      const processFile = httpsCallable(functions, 'process_uploaded_file');
      const processResult = await processFile({
        tempBlobName: fullFileId,
        originalFilename: fileDetails.filename,
        transcriptId: transcriptId
      });
  
      if (!processResult.data.success) {
        console.error('File processing failed:', processResult.data);
        throw new Error(processResult.data.message || 'File processing failed');
      }
  
      // Update the document with final GCS URI
      const transcriptRef = doc(db, 'user_transcripts', transcriptId);
      await updateDoc(transcriptRef, {
        status: 'uploaded',
        audio_uri: processResult.data.gcsUri,
        language: selectedLanguage
      });
  
      console.log('File processed successfully:', processResult.data);
      return processResult.data.gcsUri;
    } catch (error) {
      console.error('Processing error:', error);
      throw error;
    }
  };

  const handlePaymentFlow = async (paymentFunction) => {
    setPaymentProcessing(true);
    try {
      // First process the file
      const gcsUri = await processFile(fileDetails.transcriptId);
      if (!gcsUri) {
        throw new Error('File processing failed');
      }

      // Then proceed with payment
      await paymentFunction(gcsUri);
    } catch (error) {
      console.error('Error in payment flow:', error);
      setError(error.message || 'Error processing request. Please try again.');
      setPaymentProcessing(false);
    }
  };

  const handleSinglePayment = async () => {
    await handlePaymentFlow(async (gcsUri) => {
      await createCheckoutSession({
        mode: 'payment',
        line_items: [{
          price_data: {
            currency: 'eur',
            unit_amount: Math.round(price * 100),
            product_data: {
              name: 'Audio Transcription Service',
            },
          },
          quantity: 1,
        }],
        metadata: {
          transcript_id: fileDetails.transcriptId,
          totext_user_id: auth.currentUser.uid,
          payment_method_types: 'cash',
          language: selectedLanguage,
          gcs_uri: gcsUri,
          needs_processing: 'false' // File is already processed
        }
      });
    });
  };

  const handleCreditUse = async () => {
    await handlePaymentFlow(async (gcsUri) => {
      const handlePayment = httpsCallable(functions, 'handle_payment');
      const result = await handlePayment({
        operation: 'spend_credits',
        transcriptId: fileDetails.transcriptId,
        creditsNeeded: creditsNeeded,
        language: selectedLanguage,
        gcs_uri: gcsUri
      });

      if (result.data.success) {
        navigate(`/transcript/${fileDetails.transcriptId}/return`);
      } else {
        throw new Error('Credit usage failed');
      }
    });
  };

  const handleBuyCredits = async () => {
    setPaymentProcessing(true);
    try {
      await createCheckoutSession({
        mode: 'payment',
        line_items: [{
          price: CREDIT_PRICE_ID,
          quantity: 1,
        }],
        metadata: {
          credit_purchase: 'true',
          credits_amount: CREDITS_PER_PURCHASE,
          transcript_id: fileDetails.transcriptId,
          totext_user_id: auth.currentUser.uid,
          payment_method_types: 'cash',
          language: selectedLanguage
        }
      });
    } catch (error) {
      console.error('Error initiating credit purchase:', error);
      setError('Error initiating credit purchase. Please try again.');
      setPaymentProcessing(false);
    }
  };

  const handleLanguageChange = (event) => {
    const value = event.target.value === 'xx' ? '' : event.target.value;
    setSelectedLanguage(value);
  };

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    if (hours === 0) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else {
      return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes > 0 ? `and ${minutes} minute${minutes !== 1 ? 's' : ''}` : ''}`;
    }
  };

  const calculateCreditCost = (creditsNeeded) => {
    return (CREDIT_PACK_PRICE / (CREDITS_PER_PURCHASE/60)) * creditsNeeded;
  };

  const getCreditTooltip = () => {
    const creditCost = calculateCreditCost(creditsNeeded);
    return `This will cost ${creditsNeeded} credit${creditsNeeded !== 1 ? 's' : ''} (Eq. to €${creditCost.toFixed(2)})
            20 credit hours cost €${CREDIT_PACK_PRICE}`;
  };  

  if (loading || paymentProcessing) {
    return (
      <Loader size="large" fullscreen text="Preparing payment..." />
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => navigate('/upload')}
            sx={{ backgroundColor: 'primary.main' }}
          >
            Return to Upload
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <ProgressToolbar currentStep={1} />
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
          <Box sx={{ p: 3 }}>
            <Stack spacing={3}>
              {/* File Info and Language Selection */}
              <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'stretch', sm: 'flex-start' },
                gap: 2,
                width: '100%'
              }}>
                <Box sx={{ 
                  flexGrow: 1,
                  minWidth: 0, // Needed for text overflow
                }}>
                  <Typography
                    sx={{
                      typography: { xs: 'subtitle1', sm: 'subtitle1', md: 'h6' },
                      fontWeight: 500,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {fileDetails?.filename}
                  </Typography>
                </Box>
                <FormControl 
                  size="small" 
                  sx={{ 
                    minWidth: { xs: '100%', sm: 130 }, 
                    flexShrink: 0 
                  }}
                >
                  <InputLabel id="language-select-label">Language</InputLabel>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={selectedLanguage === '' ? 'xx' : selectedLanguage}
                    onChange={handleLanguageChange}
                    label="Language"
                    native
                    sx={{ fontSize: '0.875rem' }}
                  >
                    {Object.entries(languageOptions).map(([code, name]) => (
                      <option key={code} value={code === '' ? 'xx' : code}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/* Duration and Price Box */}
              <Box sx={{ 
                bgcolor: 'rgba(249, 192, 245, 0.1)', 
                p: 2, 
                borderRadius: 2
              }}>
                {duration ? (
                  <Stack spacing={1}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <Typography variant="body1" color="text.secondary">
                        Length:
                      </Typography>
                      <Typography variant="body1" color="text.secondary" fontWeight="800">
                        {formatDuration(duration)}
                      </Typography>
                    </Box>
                    <Typography variant="h4" color="primary.main" fontWeight="600">
                      {price ? `€${price.toFixed(2)}` : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <CircularProgress size={20} />
                          <Typography variant="body2" color="text.secondary">
                            Calculating price...
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Stack>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} />
                    <Typography variant="body2" color="text.secondary">
                      Calculating length and price...
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Credits Info */}
              {userCredits > 0 && (
                <Box sx={{ 
                  p: 2,
                  bgcolor: 'rgba(249, 192, 245, 0.1)',
                  borderRadius: 2,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <CreditCardIcon color="primary" />
                  <Typography>
                    {userCredits} credits available
                  </Typography>
                </Box>
              )}


              {/* Payment Buttons */}
              <Stack 
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2} 
                sx={{ 
                  '& .MuiButton-root': {
                    py: 1.5,
                    flexBasis: { sm: '50%' }
                  }
                }}
              >
                {/* Regular payment button - wrap in span when disabled */}
                {paymentProcessing || !price ? (
                  <span style={{ flexBasis: '50%' }}>
                    <Button
                      variant="contained"
                      onClick={handleSinglePayment}
                      disabled={true}
                      startIcon={<PaymentIcon />}
                      fullWidth
                    >
                      Pay €{price?.toFixed(2) || '...'}
                    </Button>
                  </span>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleSinglePayment}
                    disabled={false}
                    startIcon={<PaymentIcon />}
                    fullWidth
                  >
                    Pay €{price.toFixed(2)}
                  </Button>
                )}

                {/* Credits button - wrap in span when disabled */}
                <Tooltip title={getCreditTooltip()} arrow placement="top">
                  <span style={{ flexBasis: '50%' }}>
                    <Button
                      variant="outlined"
                      onClick={userCredits >= creditsNeeded ? handleCreditUse : handleBuyCredits}
                      disabled={paymentProcessing || !creditsNeeded}
                      startIcon={<CreditCardIcon />}
                      fullWidth
                    >
                      {userCredits >= creditsNeeded 
                        ? `Use ${creditsNeeded} credit${creditsNeeded > 1 ? 's' : ''}`
                        : 'Buy Credits'
                      }
                    </Button>
                  </span>
                </Tooltip>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentPage;