import React from 'react';

export const Logo = ({size, variant = 'square'}) => {
  // Square logo dimensions
  const getSquareDimensions = () => {
    switch(size) {
      case "small":
        return {width: "18px", height: "18px"};
      case "medium":
        return {width: "22px", height: "22px"};
      case "large":
        return {width: "33px", height: "33px"};
      default:
        return {width: "22px", height: "22px"};
    }
  };

  // Wide logo dimensions (950x200 aspect ratio maintained)
  const getWideDimensions = () => {
    switch(size) {
      case "small":
        return {width: "156px", height: "33px"};
      case "medium":
        return {width: "313px", height: "66px"};
      case "large":
        return {width: "950px", height: "200px"};
      default:
        return {width: "313px", height: "66px"};
    }
  };

  const dimensions = variant === 'wide' ? getWideDimensions() : getSquareDimensions();
  
  return (
    <img 
      src={variant === 'wide' ? "/logo.png" : "/logo_s.png"}
      alt="Logo" 
      className="transition-all duration-300"
      style={dimensions}
    />
  );
};

// Also include default export for backward compatibility
export default Logo;