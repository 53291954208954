// TranscriptToolbar.tsx
import React, { useState, useEffect } from 'react';
import { Box, Grow } from '@mui/material';
import AudioPlayer from './AudioPlayer';
import { TranscriptToolbarProps } from './types';

const TranscriptToolbar: React.FC<TranscriptToolbarProps> = ({
  audioUrl,
  audioRef,
  onTimeUpdate,
  showSpeakers,
  showTimestamps,
  onToggleSpeakers,
  onToggleTimestamps,
  concatLevel,
  onConcatLevelChange,
  segments,
  activeSegmentIndex,
  speakerColors,
  onUndo,
  onRedo,
  historyState,
  hasUnsavedChanges
}) => {
  const [show, setShow] = useState(false);

  // Trigger animation after component mounts
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Box sx={{ 
      width: '100%',
      minHeight: '64px',
      display: 'flex', 
      paddingTop: '8px',
      paddingLeft: '8px',
      paddingRight: '8px',
      alignItems: 'center',
      backgroundColor: 'transparent',
      perspective: '1000px' // Adds depth to the animation
    }}>
      <Grow 
        in={show} 
        timeout={1000}
        style={{ 
          transformOrigin: '50% 0%',
          transitionTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)' // Bouncy effect
        }}
      >
        <Box
          sx={{
            width: '100%',
            animation: show ? 'floatIn 1.2s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'none',
            '@keyframes floatIn': {
              '0%': {
                opacity: 0,
                transform: 'translateY(-20px) scale(0.95)',
              },
              '50%': {
                transform: 'translateY(10px) scale(1.02)',
              },
              '100%': {
                opacity: 1,
                transform: 'translateY(0) scale(1)',
              }
            }
          }}
        >
          <Box
            sx={{
              transform: show ? 'none' : 'translateY(-20px)',
              opacity: show ? 1 : 0,
              transition: 'all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              borderRadius: '12px',
              overflow: 'hidden',
              backgroundColor: 'white'
            }}
          >
            <AudioPlayer
              audioUrl={audioUrl}
              audioRef={audioRef}
              onTimeUpdate={onTimeUpdate}
              showSpeakers={showSpeakers}
              showTimestamps={showTimestamps}
              onToggleSpeakers={onToggleSpeakers}
              onToggleTimestamps={onToggleTimestamps}
              concatLevel={concatLevel}
              onConcatLevelChange={onConcatLevelChange}
              segments={segments}
              activeSegmentIndex={activeSegmentIndex}
              speakerColors={speakerColors}
              onUndo={onUndo}
              onRedo={onRedo}
              historyState={historyState}
              hasUnsavedChanges={hasUnsavedChanges}
            />
          </Box>
        </Box>
      </Grow>
    </Box>
  );
};

export default TranscriptToolbar;