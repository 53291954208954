// src/components/transcript/TranscriptResume.tsx
import React from 'react';
import { Box, Typography } from '@mui/material';

interface TranscriptResumeProps {
  transcriptId: string;
  segments: any[]; // Define proper type based on your segments structure
}

const TranscriptResume: React.FC<TranscriptResumeProps> = ({ transcriptId, segments }) => {
  return (
    <Box sx={{ p: 3, height: '100%', overflow: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        AI Resume Tool
      </Typography>
      {/* Add your resume tool implementation here */}
    </Box>
  );
};

export default TranscriptResume;