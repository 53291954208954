import React, { useState, useRef } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';
import { Loader } from '../common/Loader';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { Box, Typography } from '@mui/material';

const ALLOWED_FILE_TYPES = ['mp3', 'wav', 'ogg', 'm4a', 'wma', 'aac', 'flac', 'mp4', 'avi', 'mov', 'mkv', 'webm'];
const MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB in bytes

export const PreviewFileUploader = ({ onUploadComplete }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const fileInputRef = useRef(null);
  const db = getFirestore();

  // Trigger entrance animation after mount
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const circleStyle = {
    width: '75px',
    height: '75px',
    borderRadius: '50%',
    backgroundColor: '#f9c0f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    transform: isVisible 
      ? (isHovered ? 'scale(1.1) translateY(0)' : 'scale(1) translateY(0)') 
      : 'scale(0.9) translateY(20px)',
    opacity: isVisible ? 1 : 0,
    boxShadow: isHovered ? '0 4px 20px rgba(249, 192, 245, 0.4)' : 'none',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      right: '-2px',
      bottom: '-2px',
      borderRadius: '50%',
      border: '2px solid transparent',
      background: 'linear-gradient(45deg, #f9c0f5, #fcd4f8)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
      opacity: isHovered ? 1 : 0,
      transition: 'opacity 0.3s ease'
    }
  };

  const plusStyle = {
    color: 'black',
    fontSize: '100px',
    fontWeight: '100',
    fontFamily: 'Gill Sans',
    lineHeight: '10px',
    transition: 'transform 0.3s ease',
    transform: isHovered ? 'rotate(90deg)' : 'rotate(0)',
  };

  const textStyle = {
    color: '#262730',
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '16px',
    transition: 'all 0.5s ease',
    opacity: isVisible ? (isHovered ? 0.8 : 1) : 0,
    transform: isVisible 
      ? (isHovered ? 'translateY(2px)' : 'translateY(0)') 
      : 'translateY(20px)',
    transitionDelay: '0.2s',
  };

  const errorStyle = {
    color: 'red',
    textAlign: 'center',
    animation: 'slideIn 0.3s ease'
  };

  const validateAndUploadFile = async (file) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !ALLOWED_FILE_TYPES.includes(fileExtension)) {
      setError('Invalid file type. Please select an allowed file type.');
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      setError('File size exceeds 1GB limit.');
      return;
    }
  
    setIsProcessing(true);
    setError(null);
  
    try {
      const getSignedUrl = httpsCallable(functions, 'generate_signed_url');
      const { data } = await getSignedUrl({ 
        fileName: file.name,
        isPreview: true
      });
  
      if (!data.signedUrl) {
        throw new Error('No signed URL received');
      }
  
      const uploadResponse = await fetch(data.signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      });
  
      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }
  
      const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
      const previewId = `preview_${timestamp}_${Math.random().toString(36).substring(2, 10)}`;

      const transcriptRef = doc(db, 'user_transcripts', previewId);
      await setDoc(transcriptRef, {
        transcript_id: previewId,
        filename: file.name,
        status: 'uploaded',
        user_id: 'preview',
        createdAt: new Date(),
        transcript_name: file.name,
        tempBlobName: data.fileId,
        isPreview: true
      });
  
      onUploadComplete({
        fileId: data.fileId,
        filename: file.name,
        contentType: file.type,
        transcriptId: previewId,
        isPreview: true
      });
  
    } catch (error) {
      console.error('Upload error details:', error);
      setError(error.message || 'Error uploading file');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      validateAndUploadFile(files[0]);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      validateAndUploadFile(file);
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
        transition: 'opacity 0.6s ease, transform 0.6s ease',
      }}
    >
      {!isProcessing ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column', // Ensures text stays below
            alignItems: 'center',
          }}
        >
          <input
            type="file"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            ref={fileInputRef}
            accept={ALLOWED_FILE_TYPES.map(type => `.${type}`).join(',')}
          />
          
            <Box
              sx={{
                ...circleStyle,
                transitionDelay: '0.1s',
              }}
              onClick={() => fileInputRef.current?.click()}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <span style={plusStyle}>+</span>
            </Box>
            <p style={textStyle}>
              <strong>Upload audio or video file</strong>
            </p>
        </div>
      ) : (
        <Loader size="medium" text="Uploading file..." />
      )}

        {error && (
          <Box sx={{
            ...errorStyle,
            '@keyframes slideIn': {
              from: {
                transform: 'translateY(-10px)',
                opacity: 0,
              },
              to: {
                transform: 'translateY(0)',
                opacity: 1,
              },
            }
          }}>
            {error}
          </Box>
        )}
    </div>
  );
};

export default PreviewFileUploader;