// Updated AuthContainer and LogoContainer, rest stays the same
import { styled } from '@mui/material';
import { Paper, Button, TextField } from '@mui/material';

// Main container for auth forms
export const AuthContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffffde',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
  padding: theme.spacing(3),
  maxWidth: '320px',
  width: '100%',
  minWidth: '280px',
  // Remove minHeight properties
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    // Remove minHeight here too
  },

  '& .MuiBox-root': {  // Target all MuiBox-root elements
    marginTop: 0,
  }
}));

// Logo container with responsive image
export const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  '& img': {
    width: '247px',
    height: '52px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '200px', // Smaller logo on mobile
      height: 'auto', // Maintain aspect ratio
    }
  }
}));

// Rest of the styled components remain unchanged
export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '12px 16px',
  textTransform: 'none',
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

export const GoogleButton = styled(Button)(({ theme }) => ({
  padding: '12px 16px',
  textTransform: 'none',
  fontSize: '1rem',
  color: theme.palette.text.primary,
  backgroundColor: 'white',
  border: '1px solid #ddd',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));