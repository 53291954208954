// src/api/runpod_preview.js

const RUNPOD_API_KEY = process.env.REACT_APP_RUNPOD_API_KEY;
const ENDPOINT_ID = process.env.REACT_APP_RUNPOD_ENDPOINT_ID;
const BASE_URL = `https://api.runpod.ai/v2/${ENDPOINT_ID}`;

const POLL_INTERVAL = 2000; // 2 seconds

/**
 * Get job status and output
 */
const getJobStatus = async (jobId) => {
  try {
    const response = await fetch(`${BASE_URL}/status/${jobId}`, {
      headers: {
        'Authorization': `Bearer ${RUNPOD_API_KEY}`
      }
    });

    if (!response.ok) {
      throw new Error(`RunPod API error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('RunPod status check error:', error);
    throw error;
  }
};

/**
 * Run transcription job for preview
 */
const runPreviewTranscription = async (input) => {
  try {
    const response = await fetch(`${BASE_URL}/run`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${RUNPOD_API_KEY}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ input })
    });

    if (!response.ok) {
      throw new Error(`RunPod API error: ${response.status}`);
    }

    const data = await response.json();
    return data.id; // Returns job ID
  } catch (error) {
    console.error('RunPod API error:', error);
    throw error;
  }
};

/**
 * Poll for job completion
 */
const pollForCompletion = async (jobId, onProgress) => {
  while (true) {
    const status = await getJobStatus(jobId);
    
    if (onProgress) {
      onProgress(status);
    }

    if (status.status === 'COMPLETED') {
      return status.output;
    }
    
    if (status.status === 'FAILED') {
      throw new Error(status.error || 'Job failed');
    }

    // Wait before next poll
    await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL));
  }
};

/**
 * Main preview transcription function
 */
export const transcribePreview = async ({
  gcsUri,
  language = '',
  onProgress
}) => {
  // Prepare input for RunPod endpoint with preview settings
  const input = {
    audio: gcsUri,
    username: 'preview',
    language,
    max_seconds: 45, // Preview limit
    payment_method: 'preview',
    credit_cost: 0
  };

  // Start the job
  const jobId = await runPreviewTranscription(input);

  // Poll for completion
  return await pollForCompletion(jobId, onProgress);
};