import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Stack,
  LinearProgress
} from '@mui/material';
import ProgressToolbar from '../components/common/ProgressBar';

const TRANSCRIPTION_RATE = 0.07; // 0.07 seconds to transcribe 1 second of audio
const PROGRESS_STORAGE_KEY = 'transcript_progress_';

export const PaymentReturnPage = () => {
  const { transcriptId } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('');
  const [transcriptName, setTranscriptName] = useState('');
  const [progress, setProgress] = useState(() => {
    // Initialize progress from localStorage if available
    const savedProgress = localStorage.getItem(PROGRESS_STORAGE_KEY + transcriptId);
    return savedProgress ? parseFloat(savedProgress) : 0;
  });
  const [startTime, setStartTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const db = getFirestore();

  // Calculate expected total processing time
  const calculateExpectedDuration = useCallback((audioDuration) => {
    return audioDuration * TRANSCRIPTION_RATE;
  }, []);

  // Calculate current progress based on elapsed time and expected duration
  const calculateProgress = useCallback((elapsedTime, expectedDuration) => {
    const rawProgress = (elapsedTime / expectedDuration) * 100;
    return Math.min(rawProgress, 95); // Cap at 95% until complete
  }, []);

  // Save progress to localStorage when it changes
  useEffect(() => {
    if (progress > 0) {
      localStorage.setItem(PROGRESS_STORAGE_KEY + transcriptId, progress.toString());
    }
  }, [progress, transcriptId]);

  // Main subscription and progress calculation effect
  useEffect(() => {
    if (!transcriptId) {
      navigate('/');
      return;
    }

    let progressInterval = null;

    const unsubscribe = onSnapshot(
      doc(db, 'user_transcripts', transcriptId),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setTranscriptName(data.transcript_name || '');
          setStatus(data.status || '');
          
          // Set duration when available
          if (data.duration) {
            setDuration(data.duration);
          }

          // Set start time when transcription begins
          if (data.status === 'processing' && !startTime) {
            const timestamp = data.transcription_start_time?.toMillis() || Date.now();
            setStartTime(timestamp);
          }

          // Handle completion
          if (data.status === 'transcribed') {
            setProgress(100);
            clearInterval(progressInterval);
            setTimeout(() => {
              navigate(`/transcript/${transcriptId}`);
            }, 2000);
          } else if (data.status === 'failed') {
            setProgress(100);
            clearInterval(progressInterval);
          }
        }
      },
      (error) => {
        console.error('Error monitoring transcript:', error);
        setStatus('error');
      }
    );

    // Set up progress interval if we have necessary data
    if (startTime && duration) {
      progressInterval = setInterval(() => {
        const elapsedTime = (Date.now() - startTime) / 1000; // Convert to seconds
        const expectedDuration = calculateExpectedDuration(duration);
        const currentProgress = calculateProgress(elapsedTime, expectedDuration);
        setProgress(currentProgress);
      }, 1000);
    }

    // Cleanup function
    return () => {
      unsubscribe();
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    };
  }, [transcriptId, navigate, db, startTime, duration, calculateExpectedDuration, calculateProgress]);

  const getStatusText = () => {
    switch (status) {
      case 'uploaded':
        return 'Prepaering file...';
      case 'sent_to_transcription':
        return 'Processing file...';
      case 'processing':
        return 'Transcribing audio...';
      case 'transcribed':
        return 'Transcription complete!';
      case 'error':
        return 'Transcription failed';
      default:
        return 'Initializing...';
    }
  };

  const getEstimatedTimeRemaining = () => {
    if (!duration || !startTime || status !== 'transcribing') return null;
    
    const elapsedTime = (Date.now() - startTime) / 1000;
    const expectedDuration = calculateExpectedDuration(duration);
    const remainingTime = Math.max(0, expectedDuration - elapsedTime);
    
    if (remainingTime < 60) {
      return `About ${Math.ceil(remainingTime)} seconds remaining`;
    } else {
      return `About ${Math.ceil(remainingTime / 60)} minutes remaining`;
    }
  };

  return (
    <>
      <ProgressToolbar currentStep={3} />
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
          <Box sx={{ p: 3 }}>
            <Stack spacing={3} alignItems="center">
              {/* Title */}
              <Typography
                sx={{
                  typography: { xs: 'subtitle1', sm: 'subtitle1', md: 'h6' },
                  fontWeight: 500,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                textAlign="center"
              >
                {transcriptName 
                  ? `Transcribing ${transcriptName}` 
                  : 'Transcribing your file'}
              </Typography>

              {/* Progress Bar */}
              <Box sx={{ width: '100%' }}>
                <LinearProgress 
                  variant="determinate" 
                  value={progress}
                  sx={{
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: 'rgba(249, 192, 245, 0.2)',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'primary.main'
                    }
                  }}
                />
              </Box>

              {/* Status Text */}
              <Stack spacing={1} sx={{ textAlign: 'center' }}>
                <Typography variant="body1" color="text.primary">
                  {getStatusText()}
                </Typography>
                {status !== 'failed' && status !== 'transcribed' && (
                  <Typography variant="body2" color="text.secondary">
                    {getEstimatedTimeRemaining() || 'Calculating remaining time...'}
                  </Typography>
                )}
              </Stack>

              {/* Additional Info */}
              <Typography variant="body2" color="text.secondary" textAlign="center">
                You can safely close this tab and return later.
              </Typography>

              {/* Error State */}
              {status === 'failed' && (
                <Button
                  variant="contained"
                  onClick={() => navigate('/upload')}
                  sx={{ 
                    mt: 2,
                    backgroundColor: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.dark'
                    }
                  }}
                >
                  Return to Upload
                </Button>
              )}
            </Stack>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentReturnPage;