import React, { useRef } from 'react';
import { Box, CircularProgress, Typography, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const ALLOWED_FILE_TYPES = ['mp3', 'wav', 'ogg', 'm4a', 'wma', 'aac', 'flac', 'mp4', 'avi', 'mov', 'mkv', 'webm'];
const MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB in bytes

export const CompactFileUploader = ({ onUploadComplete, isCollapsed }) => {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [error, setError] = React.useState(null);
  const fileInputRef = useRef(null);
  const db = getFirestore();
  const auth = getAuth();

  const validateAndUploadFile = async (file) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !ALLOWED_FILE_TYPES.includes(fileExtension)) {
      setError('Invalid file type. Please select an allowed file type.');
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      setError('File size exceeds 1GB limit.');
      return;
    }
  
    setIsProcessing(true);
    setError(null);
  
    try {
      console.log('Starting upload process for:', file.name);
  
      // Get signed URL using Cloud Function
      const getSignedUrl = httpsCallable(functions, 'generate_signed_url');
      console.log('Requesting signed URL...');
      
      const { data } = await getSignedUrl({ fileName: file.name });
      console.log('Received signed URL response:', data);
  
      if (!data.signedUrl) {
        throw new Error('No signed URL received');
      }
  
      // Upload file using signed URL
      console.log('Uploading file to storage...');
      const uploadResponse = await fetch(data.signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      });
  
      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }
  
      console.log('File upload completed successfully');
  
      // Generate transcript ID
      const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
      const transcriptId = `${timestamp}_${file.name.replace(/[^a-zA-Z0-9]/g, '')}`;

      // Create initial Firestore document
      const transcriptRef = doc(db, 'user_transcripts', transcriptId);
      await setDoc(transcriptRef, {
        transcript_id: transcriptId,
        filename: file.name,
        status: 'uploaded',
        user_id: auth.currentUser.uid,
        createdAt: new Date(),
        transcript_name: file.name,
        tempBlobName: data.fileId, // Temporary storage location
      });
  
      // Call onUploadComplete with upload info
      onUploadComplete({
        fileId: data.fileId,
        filename: file.name,
        contentType: file.type,
        transcriptId: transcriptId
      });
  
    } catch (error) {
      console.error('Upload error details:', error);
      setError(error.message || 'Error uploading file');
    } finally {
      setIsProcessing(false);
    }
  };

  const onFileSelect = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      validateAndUploadFile(file);
    }
  };

  const UploaderContent = isCollapsed ? (
    <Tooltip title="New Transcript" placement="right">
      <Box 
        sx={{ 
          p: 2,
          display: 'flex',
          marginTop: '20px',
          marginBottom: '10px',
          justifyContent: 'center',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            backgroundColor: 'primary.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isProcessing ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <AddIcon sx={{ color: 'primary.contrastText' }} />
          )}
        </Box>
      </Box>
    </Tooltip>
  ) : (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          backgroundColor: 'primary.main',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isProcessing ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <AddIcon sx={{ color: 'primary.contrastText' }} />
        )}
      </Box>
      
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        <Typography 
          variant="body2" 
          sx={{ 
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'primary.main',
            fontWeight: 'bold',
            fontSize: "18px"
          }}
        >
          New Transcript
        </Typography>
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Box onClick={() => fileInputRef.current?.click()}>
      {UploaderContent}
      <input
        type="file"
        onChange={onFileSelect}
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept={ALLOWED_FILE_TYPES.map(type => `.${type}`).join(',')}
      />
    </Box>
  );
};

export default CompactFileUploader;