import React, { useRef, useCallback, useEffect } from 'react';
import { CustomProgressBarProps } from './types';
import { Tooltip } from '@mui/material';
import { speakerToPersonName } from './utils';

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({
  audioRef,
  duration,
  segments,
  currentTime,
  onSeek,
  activeSegmentIndex,
  speakerColors,
  showSpeakers,
  customSpeakerNames = {} // Add default empty object
}) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);

  // Calculate indicator position based on current time
  const indicatorPosition = (currentTime / duration) * 100;

  // Calculate time from mouse position
  const calculateTime = useCallback((clientX: number) => {
    if (!progressBarRef.current) return 0;
    const rect = progressBarRef.current.getBoundingClientRect();
    const clickPosition = (clientX - rect.left) / rect.width;
    return Math.max(0, Math.min(duration, clickPosition * duration));
  }, [duration]);

  // Prepare segment markers with visual properties and extend to next segment start
  const segmentMarkers = React.useMemo(() => segments.map((segment, index) => {
    const nextSegmentStart = segments[index + 1]?.start ?? duration;
    return {
      start: (segment.start / duration) * 100,
      end: (nextSegmentStart / duration) * 100,
      isActive: index === activeSegmentIndex,
      color: showSpeakers ? speakerColors[segment.speaker] : 'rgba(0, 102, 204)',
      originalIndex: index,
      startTime: segment.start,
      endTime: segment.end,
      speaker: segment.speaker,
      text: segment.text,
      displayName: customSpeakerNames[segment.speaker] || speakerToPersonName(segment.speaker)
    };
  }), [segments, duration, activeSegmentIndex, speakerColors, showSpeakers, customSpeakerNames]);

  // Handle click on segment
  const handleSegmentClick = useCallback((e: React.MouseEvent<HTMLDivElement>, segmentIndex: number) => {
    e.stopPropagation();
    const segment = segments[segmentIndex];
    if (segment) {
      onSeek(segment.start);
    }
  }, [segments, onSeek]);

  // Handle main progress bar click
  const handleProgressBarClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const newTime = calculateTime(e.clientX);
    onSeek(newTime);
  }, [calculateTime, onSeek]);

  // Handle mouse interactions
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    isDraggingRef.current = true;
    
    const target = e.target as HTMLElement;
    if (target.classList.contains('segment-marker')) {
      const segmentId = target.id;
      const segmentIndex = parseInt(segmentId.replace('segment-', ''));
      handleSegmentClick(e, segmentIndex);
    } else {
      handleProgressBarClick(e);
    }
  }, [handleSegmentClick, handleProgressBarClick]);

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const newTime = calculateTime(e.clientX);
    onSeek(newTime);
  }, [calculateTime, onSeek]);

  const handleMouseUp = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    isDraggingRef.current = false;
    const newTime = calculateTime(e.clientX);
    onSeek(newTime);
  }, [calculateTime, onSeek]);

  // Handle global mouse events
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      isDraggingRef.current = false;
    };

    const handleGlobalMouseMove = (e: MouseEvent) => {
      if (!isDraggingRef.current) return;
      const newTime = calculateTime(e.clientX);
      onSeek(newTime);
    };

    window.addEventListener('mouseup', handleGlobalMouseUp);
    window.addEventListener('mousemove', handleGlobalMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
      window.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, [calculateTime, onSeek]);

  // Generate tooltip content
  const getTooltipContent = (marker: typeof segmentMarkers[0]) => {
    const previewText = marker.text.split('.')[0].slice(0, 50) + 
      (marker.text.length > 50 ? '...' : '');
    return `${marker.displayName}: ${previewText}`;
  };

  return (
    <div className="custom-progress-bar">
      <div 
        ref={progressBarRef}
        className="progress-bar-container"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{ cursor: 'pointer', position: 'relative' }}
      >
        <div className="progress-bar-background" />
        
        {segmentMarkers.map((marker) => (
          <Tooltip
            key={marker.originalIndex}
            title={getTooltipContent(marker)}
            placement="top"
            arrow
            enterDelay={200}
            leaveDelay={0}
          >
            <div
              id={`segment-${marker.originalIndex}`}
              className={`segment-marker ${marker.isActive ? 'active' : ''}`}
              style={{
                left: `${marker.start}%`,
                width: `${marker.end - marker.start}%`,
                backgroundColor: marker.color,
                transition: 'opacity 0.3s ease',
                cursor: 'pointer',
                borderLeft: '2px solid rgba(255, 255, 255, 0.5)',
                boxSizing: 'border-box'
              }}
              onClick={(e) => handleSegmentClick(e, marker.originalIndex)}
            />
          </Tooltip>
        ))}
        
        <div 
          className="progress-indicator"
          style={{ 
            left: `${indicatorPosition}%`,
            transition: isDraggingRef.current ? 'none' : 'left 0.1s linear'
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(CustomProgressBar);