// /workspaces/totext-app/src/components/drawer/TranscriptListItem.js

import React, { useState } from 'react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { 
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


const TranscriptListItem = ({ 
  transcript, 
  isActive, 
  isDrawerOpen, 
  onClick,
  onUpdate 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(transcript.transcript_name);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  
  const db = getFirestore();
  const storage = getStorage();

  const handleRename = async () => {
    try {
      const transcriptRef = doc(db, 'user_transcripts', transcript.transcript_id);
      await updateDoc(transcriptRef, {
        transcript_name: newName
      });
      setIsEditing(false);
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Error renaming transcript:', error);
    }
  };

  const handleDelete = async () => {
    try {
      // Update status in Firestore
      const transcriptRef = doc(db, 'user_transcripts', transcript.transcript_id);
      await updateDoc(transcriptRef, {
        status: 'deleted'
      });

      // Delete files from Storage if they exist
      const deleteGcsFile = async (gcsUri) => {
        if (gcsUri && gcsUri.startsWith('gs://')) {
          try {
            // We'll use the full GCS URI directly with the ref
            const fileRef = ref(storage, gcsUri);
            await deleteObject(fileRef);
          } catch (error) {
            console.error('Error deleting file:', error);
          }
        }
      };

      if (transcript.audio_uri) {
        await deleteGcsFile(transcript.audio_uri);
      }
      if (transcript.text_gcs_uri) {
        await deleteGcsFile(transcript.text_gcs_uri);
      }

      setShowDeleteDialog(false);
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Error deleting transcript:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleRename();
    } else if (event.key === 'Escape') {
      setIsEditing(false);
      setNewName(transcript.transcript_name);
    }
  };

  if (!isDrawerOpen) {
    return (
      <Tooltip title={transcript.transcript_name} placement="right">
        <ListItem disablePadding>
          <ListItemButton 
            onClick={onClick}
            sx={{
              backgroundColor: isActive ? 'rgba(249, 192, 245, 0.2)' : 'transparent',
              '&:hover': {
                backgroundColor: isActive ? 'rgba(249, 192, 245, 0.3)' : 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                marginTop: '26px',
                marginBottom: '26px',
                backgroundColor: 'primary.main',
                mx: 'auto'
              }}
            />
          </ListItemButton>
        </ListItem>
      </Tooltip>
    );
  }

  return (
    <>
      <ListItem
        disablePadding
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ListItemButton 
          onClick={!isEditing ? onClick : undefined}
          sx={{
            backgroundColor: isActive ? 'rgba(249, 192, 245, 0.2)' : 'transparent',
            '&:hover': {
              backgroundColor: isActive ? 'rgba(249, 192, 245, 0.3)' : 'rgba(0, 0, 0, 0.04)'
            },
            py: 1
          }}
        >
          {isEditing ? (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <TextField
                fullWidth
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onKeyDown={handleKeyPress}
                autoFocus
                size="small"
                sx={{ mr: 1 }}
              />
              <IconButton size="small" onClick={handleRename}>
                <CheckIcon fontSize="small" />
              </IconButton>
              <IconButton 
                size="small" 
                onClick={() => {
                  setIsEditing(false);
                  setNewName(transcript.transcript_name);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <>
              <ListItemText 
                primary={transcript.transcript_name}
                secondary={
                  <Typography variant="caption" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                    {transcript.language?.toUpperCase() || 'N/A'} • {Math.round(transcript.duration / 60)}:
                    {String(Math.round(transcript.duration % 60)).padStart(2, '0')} • 
                    {transcript.createdAt?.toDate().toLocaleDateString('en-GB')}
                  </Typography>
                }
                primaryTypographyProps={{
                  noWrap: true
                }}
              />
              {isHovered && (
                <Box sx={{ display: 'flex', ml: 1 }}>
                  <IconButton 
                    size="small" 
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(true);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteDialog(true);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </>
          )}
        </ListItemButton>
      </ListItem>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Delete Transcript</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete "{transcript.transcript_name}"? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TranscriptListItem;