import React, { useState } from 'react';
import { 
  Toolbar, 
  IconButton, 
  Tooltip, 
  Menu, 
  MenuItem, 
  CircularProgress,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { functions } from '../../config/firebase';
import { httpsCallable } from 'firebase/functions';
import DownloadIcon from '@mui/icons-material/Download';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ArticleIcon from '@mui/icons-material/Article';
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';

export const TranscriptNavbar = ({ 
  transcriptId, 
  segments, 
  transcriptName,
  onSave,
  onRevert,
  hasUnsavedChanges
}) => {
  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [revertDialogOpen, setRevertDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSave = async () => {
    try {
      setSaveLoading(true);
      await onSave();
    } catch (error) {
      console.error('Error saving transcript:', error);
      setErrorMessage('Failed to save transcript. Please try again.');
      setIsErrorDialogOpen(true);
    } finally {
      setSaveLoading(false);
    }
  };

  const handleRevert = async () => {
    setRevertDialogOpen(false);
    try {
      await onRevert();
    } catch (error) {
      console.error('Error reverting transcript:', error);
      setErrorMessage('Failed to revert transcript. Please try again.');
      setIsErrorDialogOpen(true);
    }
  };

  const handleDownload = async (format) => {
    setDownloadLoading(true);
    handleMenuClose();

    try {
      // If there are unsaved changes, save first
      if (hasUnsavedChanges) {
        await handleSave();
      }

      const generateDownload = httpsCallable(functions, 'generate_transcript_download');
      const result = await generateDownload({
        transcriptId,
        format,
        name: transcriptName
      });

      let content = result.data.content;
      if (result.data.encoding === 'base64') {
        content = atob(content);
      }
      
      const blob = new Blob([content], { type: result.data.mimeType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${transcriptName}.${format}`;
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download error:', error);
      setErrorMessage('Failed to download transcript. Please try again.');
      setIsErrorDialogOpen(true);
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <Toolbar 
        variant="dense" 
        sx={{ 
          height: '48px', 
          bgcolor: '#fbf9f9',
          display: 'flex',
          justifyContent: 'space-between',
          borderWidth: 0,
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.12)',
          borderBottomWidth: 'thin',
          marginBottom: '2px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {transcriptName}
          </Typography>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1
        }}>
          <Tooltip title={hasUnsavedChanges ? "Save changes" : "No unsaved changes"}>
            <span>
              <IconButton 
                onClick={handleSave}
                disabled={saveLoading || !hasUnsavedChanges}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(249, 192, 245, 0.2)'
                  }
                }}
              >
                {saveLoading ? <CircularProgress size={20} /> : <SaveIcon />}
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Revert to original">
            <IconButton 
              onClick={() => setRevertDialogOpen(true)}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(249, 192, 245, 0.2)'
                }
              }}
            >
              <RestoreIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download">
            <IconButton 
              onClick={handleMenuOpen}
              disabled={downloadLoading}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(249, 192, 245, 0.2)'
                }
              }}
            >
              {downloadLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleDownload('txt')}>
              <TextSnippetIcon sx={{ mr: 1 }} /> Text File (.txt)
            </MenuItem>
            <MenuItem onClick={() => handleDownload('srt')}>
              <SubtitlesIcon sx={{ mr: 1 }} /> Subtitles (.srt)
            </MenuItem>
            <MenuItem onClick={() => handleDownload('docx')}>
              <ArticleIcon sx={{ mr: 1 }} /> Word Document (.docx)
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      {/* Revert Confirmation Dialog */}
      <Dialog
        open={revertDialogOpen}
        onClose={() => setRevertDialogOpen(false)}
      >
        <DialogTitle>Revert Transcript</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to revert to the original transcript? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRevertDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRevert} color="error">Revert</Button>
        </DialogActions>
      </Dialog>

      {/* Error Dialog */}
      <Dialog
        open={isErrorDialogOpen}
        onClose={() => setIsErrorDialogOpen(false)}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsErrorDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TranscriptNavbar;