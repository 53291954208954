// src/components/preview/PreviewTranscriptDisplay.js
import React from 'react';
import { Box, Typography, Stack, Button, Paper } from '@mui/material';

export const PreviewTranscriptDisplay = ({ 
  transcriptData,
  onLogin,
  filename,
  price
}) => {
  // Generates a preview text showing first few segments
  const formatPreviewSegments = (segments) => {
    // Show only first few segments
    const previewSegments = segments.slice(0, 3);
    
    return previewSegments.map((segment, index) => (
      <Stack 
        key={index} 
        direction="row" 
        spacing={2} 
        sx={{ mb: 2 }}
      >
        {/* Speaker label with pink background */}
        <Box sx={{ minWidth: 100 }}>
          <Typography
            variant="body2"
            sx={{
              backgroundColor: '#f9c0f5',
              padding: '2px 8px',
              borderRadius: '4px',
              display: 'inline-block'
            }}
          >
            Person 1
          </Typography>
        </Box>
        
        {/* Timestamp */}
        <Box sx={{ minWidth: 80 }}>
          <Typography variant="body2" color="text.secondary">
            {formatTime(segment.start)}
          </Typography>
        </Box>
        
        {/* Text content */}
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1">
            {segment.text}
          </Typography>
        </Box>
      </Stack>
    ));
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  if (!transcriptData || !transcriptData.segments) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Paper 
        elevation={2}
        sx={{ 
          p: 3,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '100px',
            background: 'linear-gradient(transparent, white)',
            pointerEvents: 'none'
          }
        }}
      >
        {/* Preview content */}
        <Box sx={{ mb: 4 }}>
          {formatPreviewSegments(transcriptData.segments)}
        </Box>

        {/* Call to action */}
        <Box 
          sx={{ 
            position: 'sticky',
            bottom: 0,
            bgcolor: 'white',
            pt: 2,
            pb: 1,
            borderTop: '1px solid rgba(0,0,0,0.1)',
            zIndex: 1
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6" align="center">
              Get the full transcript of {filename}
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center">
              Just €{price.toFixed(2)} or even cheaper with credits
            </Typography>
            <Button 
              variant="contained" 
              color="primary"
              onClick={onLogin}
              sx={{ 
                minWidth: 200,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark'
                }
              }}
            >
              Sign up or Login
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export default PreviewTranscriptDisplay;