import './App.css';
import firebaseConfig from "./config/firebaseConfig.json";
import authMethods from "./config/authMethods.json";
import { 
  pathnames, 
  AuthProvider, 
  AuthRoutes, 
  PublicTemplate, 
  UserUpdateEmail,
  UserUpdateName,
  UserUpdatePassword,
  UserDelete,
  FireactProvider 
} from '@fireactjs/core';
import { UserProfile } from './components/user/CustomUserComponents';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UploadPage } from './pages/UploadPage';
import { PaymentPage } from './pages/PaymentPage';
import { PaymentReturnPage } from './pages/PaymentReturnPage';
import { EditTranscriptPage } from './pages/TranscriptPage';
import { CustomSignIn } from './components/auth/CustomSignIn';
import { CustomSignUp } from './components/auth/CustomSignUp';
import { PreviewPage } from './pages/PreviewPage';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './config/theme';
import { Logo } from './components/common/Logo';
import { Loader } from './components/common/Loader';
import { CustomMenu } from './components/drawer/CustomMenu';
import { AppTemplate } from './components/common/AppTemplate';

function App() {
  // Add custom pathnames for our routes with consistent parameter naming
  const customPathnames = {
    ...pathnames,
    Upload: "/upload",
    Payment: "/payment/:prefix?/:fileId",
    PaymentWithPrefix: "/payment/uploads/:fileId",  // For IDs with uploads/ prefi
    PaymentReturn: "/transcript/:transcriptId/return",
    EditTranscript: "/transcript/:transcriptId", // Updated to use transcriptId
  };

  const config = {
    firebaseConfig: firebaseConfig,
    brand: "",
    pathnames: customPathnames,
    authProviders: authMethods
  };

  return (
    <ThemeProvider theme={theme}>
      <FireactProvider config={config}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<AuthRoutes loader={<Loader size="large" fullscreen />} />} >
                <Route element={
                  <AppTemplate 
                    logo={<Logo size="small" variant="wide" />}
                    drawerMenu={<CustomMenu />}
                    toolBarMenu={null}  // This will remove the menu but not the toolbar
                  />
                }>
                  {/* Our custom routes */}
                  <Route exact path="/" element={<UploadPage />} />
                  <Route exact path={customPathnames.Upload} element={<UploadPage />} />
                  <Route exact path={customPathnames.Payment} element={<PaymentPage />} />
                  <Route exact path={customPathnames.PaymentWithPrefix} element={<PaymentPage />} />
                  <Route exact path={customPathnames.PaymentReturn} element={<PaymentReturnPage />} />
                  <Route exact path={customPathnames.EditTranscript} element={<EditTranscriptPage />} />
                  
                  {/* Built-in user profile routes */}
                  <Route exact path={pathnames.UserProfile} element={<UserProfile />} />
                  <Route exact path={pathnames.UserUpdateEmail} element={<UserUpdateEmail />} />
                  <Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
                  <Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
                  <Route exact path={pathnames.UserDelete} element={<UserDelete />} />
                </Route>
              </Route>
              <Route element={<PublicTemplate />}>
                <Route path="/preview" element={<PreviewPage />} />
                <Route 
                  path={pathnames.SignIn} 
                  element={<CustomSignIn logo={<Logo size="large" />} />} 
                />
                <Route 
                  path={pathnames.SignUp} 
                  element={<CustomSignUp logo={<Logo size="large" />} />} 
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </FireactProvider>
    </ThemeProvider>
  );
}

export default App;